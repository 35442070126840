import React, { useState } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/audit/audit.module.scss'
import Tab from '../../../uicomponents/tab'
import withTabGroup from '../../../hocs/withTabGroup'
import Heading from '../../../uicomponents/heading'
import MainQmf from '../qmf/mainQmf'
import MainIqc from '../iqc/mainIqc'
import jwt_decode from "jwt-decode"
import constants from '../../../util/constants'

let TABS = ['IQC', 'Performance audit']
const roles = localStorage.authToken ? [jwt_decode(localStorage.authToken).auth] : [];

const isTeamLead = () => {
    return roles && roles.includes(constants.customRole.TEAM_LEAD) ? true : false
}

const Audit = (props) => {
    const TabGroup = withTabGroup(Tab, 'group');
    const [activeTab, setActiveTab] = useState(props.location.state && 
        props.location.state.activeTab ? props.location.state.activeTab : isTeamLead() ? TABS[1] : TABS[0])


    const renderTable = () => {
        props.location.state = {
            activeTab: activeTab,
            page: props && props.location && props.location.state && props.location.state.page ?
            props.location.state.page : undefined,
            query: props && props.location && props.location.state && props.location.state.query ?
            props.location.state.query : undefined,
        }
        switch (activeTab) {
            case TABS[0]:
                return <MainIqc {...props} />
            case TABS[1]:
                return <MainQmf {...props} />
            default:
                return <h2>Please refresh the page</h2>
        }
    }

    const filterTabs = (tabs) => {
        if(isTeamLead()){
            let tabToShow = TABS[1]
            return [tabToShow]
        }
        return tabs
    }
    return(
        <div className={styles.page_wrapper}>
           <TabGroup tabs={filterTabs(TABS)} color={'navy-blue-tab-bottom-border'} 
            onClick={(value) => {
                setActiveTab(value)
                props.history.push({
                    pathname: `${props.location.pathname}`,
                    search: ''
                })
            }} activeTab={activeTab}
            />
            <div className={styles.main_qmf_row}>
                {/* <Heading text={'QMF (New)'} /> */}
                {/* <FutworkButton buttonSize='sm' buttonStyle='primary--solid non-rounded'
                onClick={() => setReportModal({type: 'QMF Calls Audit Report', isOpen: true})}
                >SEND REPORT</FutworkButton> */}
            </div>
            {renderTable()}
        </div>
    )
}

export default React.memo(Audit)