import React, {useState, useEffect} from 'react'
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faMessage } from '@fortawesome/free-solid-svg-icons';
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Table from '../../../uicomponents/table/Table'
import {getWorkingCallers, matchTelecaller, getCallerJobHistory} from './service'
import useApi from '../../../hooks/useApi'
import Popup from '../../../uicomponents/popup'
import JobsHistory from './jobHistory'
import CurrentJobs from './currentJob'
import AvailableJobs from './availableJobsTable'
import CallerInfo from './callerInfo'
import FrappButton from '../../../components/utilities/FrappButton'
import {TrackHistory, MatchedIcon, UserIcon} from '../../../assets/images'
import BasePay from './basePay'
import CheckBasePayBeforeMatching from './checkBasePayBeforeMatching'
import { getCallerJobRemark } from '../../hiring/service';
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader'


const WorkingTable = () => {
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [showRemarkPopup, setShowRemarkPopup] = useState(false);
    const [showCallerRemark, setShowCallerRemark] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const [showCallerHistory, setShowCallerHistory] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')
    const [showAvailableJobs, setShowAvailableJobs] = useState(false)
    const [showActiveJobs, setShowActiveJobs] = useState(false)
    const [showCallerInfo, setShowCallerInfo] = useState(false)
    const [showBasePayEdit, setShowBasePayEdit] = useState(false)
    const [showEarningAlert, setShowEarningAlert] = useState(false)

    const getWorkingCallerApi = useApi(getWorkingCallers)
    const matchTelecallerApi = useApi(matchTelecaller)
    const getCallerJobHistoryApi = useApi(getCallerJobHistory);


    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getWorkingCallerApi.request(skip,limit,sort,search)
    },[currentPage, sort,search])

    const columns = [
        {
            Header: "Name",
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            disableSortBy: true
        },
        {
            Header: "Mobile",
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Email',
            accessor: "email",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: "Avg Mins/day (7days)",
            accessor: "avgCallsMinutesPerDayLastWeek",
            Cell: ({value}) => (value ? value : 0)
        },
        {
            Header: "Total Minutes",
            accessor: "totalWorkMinutes",
            Cell: ({value}) => (value ? value : 0)
        },
        {
            Header: "Last seen",
            accessor: "lastSeen",
            Cell: ({value}) => (value ? moment(value).fromNow() : 'NA')
        },
        // {
        //     Header: 'Last call made',
        //     accessor: "lastCallMade",
        //     Cell: ({value}) => (value ? moment(value).fromNow() : 'NA')
        // },
        {
            Header: 'Caller info',
            Cell: (cell) => (
                <img src={UserIcon} alt='Caller info'
                onClick={()=>{
                    setShowCallerInfo(true)
                    setSelectedRowData(cell.row.original)
                }}
                />
            ),
            disableSortBy: true

        },
        {
            Header: "Current jobs",
            accessor: "activeJobCount",
            Cell: ({cell}) => (
                <div className={styles.blue_link}
                onClick={() => {
                    setSelectedRowData(cell.row.original)
                    setShowActiveJobs(true)
                }}>
                Jobs ({cell.row.original.activeJobCount ?
                cell.row.original.activeJobCount : 0})
                </div>
            ),
        },
        {
            Header: "Job history",
            Cell: (cell) => (
                <img src={TrackHistory} alt='Job History'
                onClick={() => {
                    setShowCallerHistory(true)
                    setSelectedRowData(cell.row.original)
                }}
                />
            ),
            disableSortBy: true
        },
        {
            Header: "Remark",
            accessor: 'remark',
            Cell: ({ row }) => {
                return (
                    <FontAwesomeIcon
                        icon={faMessage}
                        className={styles.remark_icon}
                        onClick={() => {
                            setSelectedRowData(row.original);
                            getCallerJobHistoryApi.request({ user: row.original.id, status: "quit" });
                            setShowRemarkPopup(true);
                        }}
                    />

                );
            },
            disableSortBy: true,
        },
        {
            Header: 'Base pay',
            accessor: 'earnings',
            width: '10%',
            Cell: (cell) => {
               const value = cell.row.original && cell.row.original.earnings ? cell.row.original.earnings : ''
               return <div className={styles.earnings}>{value ? <div className={styles.earings_present}>₹ {value}</div> : <div>₹ 0</div> }
               <FontAwesomeIcon icon={faPenToSquare} className={styles.earnings_edit} onClick={() => {
                setShowBasePayEdit(true)
                setSelectedRowData(cell.row.original)
               }}/></div>
            },
            disableSortBy: true

        },
        {
            Header: '',
            accessor: "action",
            Cell: (cell) => (
            <img src={MatchedIcon} alt='Matched Caller'
            onClick={() =>{
                if((cell.row.original && cell.row.original.earnings && cell.row.original.earnings == 0) ||
                    cell.row.original && !cell.row.original.earnings){
                    setShowEarningAlert(true)
                    setSelectedRowData(cell.row.original)
                }else{
                    setShowAvailableJobs(true)
                    setSelectedRowData(cell.row.original)
                }
            }}
            />
            ),
            disableSortBy: true
        }
    ]

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const filterChange = (d) => {
        setSearch(d)
        setCurrentPage(1)
    }

    const {data, total} = getWorkingCallerApi.data || {}
    const {loading} = getWorkingCallerApi

    const renderRemarkPopup = () => {
        if (!getCallerJobHistoryApi.data) {
            return null;
        }
    
        let quitRemarks = [];
        getCallerJobHistoryApi.data.forEach(project => {
            if (project.statusChangeLog && project.statusChangeLog.length > 0) {
                const latestLog = project.statusChangeLog.reduce((latest, log) => {
                    const logDate = new Date(log.updatedAt);
                    const latestDate = latest ? new Date(latest.updatedAt) : null;
                    return (!latestDate || logDate > latestDate) ? log : latest;
                }, null);
    
                if (latestLog && latestLog.reason.includes("Quit") && latestLog.remark) {
                    quitRemarks.push({
                        teleproject: project.teleproject, 
                        remark: latestLog.remark, 
                        date: latestLog.updatedAt, 
                        reportedBy: latestLog.actionBy 
                    });
                }
            }
        });
        // sort the remarks by latest first 
        quitRemarks.sort((a, b) => new Date(b.date) - new Date(a.date));
    
        const remarkColumns = [
            { Header: 'Project ID', accessor: 'teleproject', disableSortBy: true },
            { Header: 'Remark', accessor: 'remark', disableSortBy: true },
            { Header: 'Date of Remark', accessor: 'date',
            Cell: ({cell}) => {
                const {date: value} = cell.row.original
                return <div>{value ? 
                    <span>{moment(value).format("DD/MM/YYYY")} | {moment(value).format('hh:mm A')}</span>
                    : 'N/A'
                }</div>
            },
            // Cell: ({ value }) => (value ? new Date(value).toLocaleString() : 'N/A'), 
            disableSortBy: true },
            { Header: 'Reported By', accessor: 'reportedBy', disableSortBy: true }
        ];
    
        return (
            <Popup
                show={showRemarkPopup}
                closePopup={() => {
                    setSelectedRowData("");
                    setShowRemarkPopup(false);
                    getCallerJobHistoryApi.reset();
                }}
                size="md" 
                heading={<div>
                    <div>Remark</div>
                    <div className={styles.render_remark_divider}></div>
                    </div>}
                popupContent={
                    getCallerJobHistoryApi.loading ? (
                        <div style={{ textAlign: "center" }}>
                            <ThreeDotLoader />
                        </div>
                    ) : quitRemarks.length > 0 ? (
                        <Table
                            columns={remarkColumns}
                            data={quitRemarks}
                            totalCount={quitRemarks.length}
                            limit={10} 
                            currentPage={1} 
                            setCurrentPage={() => {}} 
                            loading={false}
                            sortChange={() => {}} 
                            filterChange={() => {}} 
                            isGlobalFilter={false}
                            type = 'client'
                        />
                    ) : (
                        <div className={styles.render_remark_fallback}>
                            <img
                                src="https://play-lh.googleusercontent.com/ue1pf33HUuVLlZ_Xiysai5Akr6yugLtU502nZPFtibDFUVfkLG-K_aH4qNOHrdGx2vh8"
                                alt="No Remark Available"
                                style={{ width: "150px", marginBottom: "10px" }}
                            />
                            <p className={styles.fallback_text}>No remark added</p>
                        </div>
                    )
                }
            />
        );
    };

    const renderCallerInfo = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showCallerInfo}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerInfo(false)
            }}
            size='sm'
            heading={`Caller profile and information of ${firstname} ${lastname}`}
            popupContent = {<CallerInfo rowData={selectedRowData} callerPreference={true}/>}
            />
        )
    }

    const renderJobHistory = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showCallerHistory}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerHistory(false)
            }}
            size='lg'
            heading={`Job history of ${firstname} ${lastname}`}
            popupContent = {<JobsHistory rowData={selectedRowData}/>}
            />
        )
    }

    const renderActiveJobs = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showActiveJobs}
            closePopup={()=>{
                setSelectedRowData('')
                setShowActiveJobs(false)
            }}
            size='lg'
            heading={`Current jobs of ${firstname} ${lastname}`}
            popupContent = {<CurrentJobs rowData={selectedRowData}/>}
            />
        )
    }

    const closeAvailableJobs = () => {
        setSelectedRowData('')
        setShowAvailableJobs(false)
        matchTelecallerApi.reset()
    }

    useEffect(()=>{
        if(matchTelecallerApi && matchTelecallerApi.data && matchTelecallerApi.data.data.status == 200){
            // let findIndex = data.findIndex(user => user.id == selectedRowData.id)
            // if(findIndex !== -1){
            //     data.splice(findIndex, 1);
            // }
            const skip = (currentPage - 1) * limit
            getWorkingCallerApi.request(skip,limit,sort)
            toast(matchTelecallerApi.data.data.msg)
            setTimeout(() => {
                closeAvailableJobs()
            }, 2000);
        }

        if(matchTelecallerApi && matchTelecallerApi.error){
            toast(matchTelecallerApi.error.msg)
        }
    },[matchTelecallerApi.data, matchTelecallerApi.error])

    const renderMatchJobs = () => {
        let projectData = ''


        const matchToJob = () => {
            const payload = {
                teleproject: projectData.id,
                user: selectedRowData.id
            }
            matchTelecallerApi.request(payload)
        }

        const ActionBtns = () => {
            return <FrappButton className="submit" id="new-btn" handler={matchToJob}>MATCH</FrappButton>
        }
        
        const passProjectSelected = (data) => {
            projectData = data
        }

        return(
            <Popup
            show={showAvailableJobs}
            closePopup={()=>{
                closeAvailableJobs()
            }}
            size='lg'
            heading={'Jobs currently open for hiring'}
            popupContent = {<AvailableJobs userData={selectedRowData} passProjectSelected={passProjectSelected}/>}
            popupFooter = {<ActionBtns/>}
            footerAlign = 'right'
            />
        )
    }

    const renderBasePay = () => {
        return <Popup
        show={showBasePayEdit}
        closePopup={()=>{
            setSelectedRowData('')
            setShowBasePayEdit(false)
        }}
        size='sm'
        heading={`Edit base pay`}
         >
            <BasePay rowData={selectedRowData} updateMessage={updateMessage}/>
        </Popup>
    }

    const renderAlert = () => {
        const props = {
            setShowEarningAlert,
            setShowBasePayEdit
        }
        return <Popup
        show={showEarningAlert}
        closePopup={() => {
            setSelectedRowData('')
            setShowEarningAlert(false)
        }}
        size='sm'
        heading = ''
        >
            <CheckBasePayBeforeMatching {...props}/>
        </Popup>
    }

    const updateMessage = (result = '') => {
        if(result){
            data.map(item => {
                console.log(item)
                if((item.userId == result.id) || (item.id == result.id)){
                    item.earnings = result.earnings
                    return item
                }
                return item
            })
        }
        setSelectedRowData('')
        setTimeout(()=>{
            setShowBasePayEdit(false)
        },500)
    }

    const handlePopups = () => {
        if(showCallerHistory) return renderJobHistory()
        if(showAvailableJobs) return renderMatchJobs()
        if(showActiveJobs) return renderActiveJobs()
        if(showCallerInfo) return renderCallerInfo()
        if(showBasePayEdit) return renderBasePay()
        if(showEarningAlert) return renderAlert()
        if (showRemarkPopup) return renderRemarkPopup();
        else
        return null
    }

    return(
        <>
        <Table
        columns={columns}
        data={data}
        totalCount={total}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        sortChange={sortChange}
        filterChange = {filterChange}
        isGlobalFilter = {true}
        />
        {handlePopups()}
        <ToastContainer draggable={true} />
        </>
    )
}

export default WorkingTable