import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, matchPath, withRouter } from "react-router-dom";
import QualityAudit from '../components/pages/QualityAudit';
import QualityCompliance from '../components/pages/QualityCompliance'
import AuditProject from '../components/pages/AuditProject'
import ProjectQmf from '../pages/audit/qmf/projectQmf'
import MainQmf from '../pages/audit/qmf/mainQmf'
import CallsListOfCaller from '../pages/audit/qmf/callsListOfCaller'
import IqcCallsListOfCaller from '../pages/audit/iqc/callsListOfCaller'
import QmfForm from '../pages/audit/qmf/qmfForm'
import IqcQmfForm from '../pages/audit/iqc/qmfForm'
import RebuttalQmfForm from '../pages/audit/rebuttal/qmfForm'
import Audit from '../pages/audit/main'
import Rebuttal from '../pages/audit/rebuttal';
import { RebuttalProvider } from '../pages/audit/rebuttal/rebuttalContext';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);
  
    useEffect(() => {
      const mediaQuery = window.matchMedia("(max-width: 768px)");
      const handleResize = () => setIsMobile(mediaQuery.matches);
  
      mediaQuery.addEventListener("change", handleResize);
      return () => mediaQuery.removeEventListener("change", handleResize);
    }, []);
  
    return isMobile;
};

const AuditRouter = (props) => {
    const { path } = props.match
    const isMobile = useIsMobile()
    if(isMobile){
        alert("Guess you are on your Mobile Phone📱, try using laptop💻")
        props.history.push("/")
    }
    let customProps = {
        ...props,
        modulePath: path
    }


    const match = matchPath(props.location.pathname, {
        path: '/audit/qmf/audit-project/:id',
        exact: true,
        strict: false
     })
     if(match) {
         customProps.match = match
     }

    return <Switch>
        <Route exact path={`${path}/v2`} render={() => <Audit {...customProps} />}/>

        <Route exact path={`${path}/qmf`}
        render={() => <ProjectQmf {...customProps} />}
        />

        <Route exact path={`${path}/v2/qmf/calls-list/:userId`}
        render={() => <CallsListOfCaller {...customProps} />}
        />

        <Route exact path={`${path}/v2/iqc/calls-list/:userId`}
        render={() => <IqcCallsListOfCaller {...customProps}/>} />

        <Route exact path={`${path}/v2/qmf/qmf-form/:userId`}
        render={() => <QmfForm {...customProps} />}
        />

        <Route exact path={`${path}/v2/iqc/qmf-form/:userId`}
        render={() => <IqcQmfForm {...customProps} />}
        />
        <Route exact path={`${path}/qmf/audit-project/:id`}
        render={() => <AuditProject {...customProps} />}
        />

        <Route exact path={`${path}/qa-compliance`}
        render={() => <QualityCompliance {...customProps} />}
        />

        {/* rebuttal module route */}
        <RebuttalProvider>
            <Route exact path={`${path}/rebuttal`}
            render={() => <Rebuttal {...customProps} />}
            />

            <Route exact path={`${path}/rebuttal/qmf-form`}
            render={() => <RebuttalQmfForm {...customProps} />}
            />
        </RebuttalProvider>
      

        {/* if there is no route match then redirect to the root path */}
        {/* <Route render={() => <Redirect to="/" />} /> */}
    </Switch>
}

export default AuditRouter