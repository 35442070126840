import React, { Component } from 'react'
import ApiActions from '../../actions/ApiActions'
import Loader from '../utilities/Loader';
import moment from "moment";
class TringDashboard extends Component {


    constructor(props) {
        super(props)
        this.state = {
            projectStats: {},
            callerStats: {},
            bizStats: {},
            bizStatsCurr: {},
            bizCreditsMtd: {},
            projectStatsTotal: [],
            isLoading: true,
            isBusinessInsights: false
        }
    }


    componentWillMount() {
        let { projectStats, callerStats, bizCreditsMtd, bizStats } = this.state;
        this.fetchData()
    }

    createObjWithDynamicKeys = (data) => {
        return data.reduce(function (totalVal, currVal, currIdx) {
            Object.keys(currVal).forEach(elem => {
                currVal[elem].name = elem
                totalVal.push(currVal[elem])
            })
            return totalVal;
        }, [])
    }

    getTeleCallerStats = () => {
        ApiActions.gettelecallerstatsbydate().then(result => {
            if(result)
            this.setState({ callerStats: this.createObjWithDynamicKeys([result.data]) })
            this.setState({ isLoading: false })
        }).catch(err => console.error(err))
    }

    getProjectStatsByDate = () => {
        ApiActions.getteleprojectstatsbydate().then(result => {
            if(result)
            this.setState({ projectStats: this.createObjWithDynamicKeys([result.data]) })
            this.setState({ isLoading: false })
        }).catch(err => console.error(err))
    }

    getBusinessstatsOfToday = () => {
        var today = new Date();
        // const from = moment(today).startOf('day').toDate()
        // const to = moment(today).endOf('day').toDate()
        const to = moment().format("YYYY-MM-DD")
        const from = moment().format("YYYY-MM-DD")
    
        ApiActions.getbusinessstatsbydate(from, to).then((result =>{
            if(result)
            if(!this.state.isBusinessInsights){
                this.setState({isBusinessInsights: true})
            }
            let filterDailyMinutes = this.createObjWithDynamicKeys([result.data]).filter(obj=> obj.name == 'totalDurationInMinutes')
            let formattedObj = filterDailyMinutes.map(obj=>{
                return{
                    ...obj,
                    name: 'dailyMinutes'
                }
            })
            this.setState({isdailyMinutes: formattedObj[0]})
            this.setState({ isLoading: false })
        })).catch(err=> console.error(err))
    }

    getBusinessStats = () => {
        ApiActions.getbusinessstats().then(result => {
            if(result)
            if(!this.state.isBusinessInsights){
                this.setState({isBusinessInsights: true})
            }
            let formattedObj = this.createObjWithDynamicKeys([result.data]).map(obj=>{
                return{
                    ...obj,
                    name: 'totalOverallCredits'
                }
            })
            this.setState({istotalOverallCredits: formattedObj[0]})
            this.setState({ isLoading: false })
        }).catch(err => console.error(err))
    }

    getBusinessStatsCurrentMonth = () => {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        // var firstDay = new Date(y, m, 1);
        // var lastDay = new Date(y, m + 1, 0);
        var firstDay = moment(new Date(y, m, 1)).format("YYYY-MM-DD")
        var lastDay = moment().format("YYYY-MM-DD")

        ApiActions.getbusinessstatsbydate(firstDay, lastDay).then(result => {
            if(result)
            if(!this.state.isBusinessInsights){
                this.setState({isBusinessInsights: true})
            }
            let formattedObj = this.createObjWithDynamicKeys([result.data]).map(obj => {
                if(obj.name == 'totalCredits'){
                    return {
                        ...obj,
                        name: 'monthlyCredits'
                    }
                }
                if(obj.name == 'totalDurationInMinutes'){
                    return {
                        ...obj,
                        name: 'monthlyMinutes'
                    }
                }
                return obj
            })
            this.setState({ismonthlyCredits: formattedObj[0]})
            this.setState({ismonthlyMinutes: formattedObj[1]})
            this.setState({ isLoading: false })
        }).catch(err => console.error(err))
    }

    fetchData = () => {
        this.getTeleCallerStats()
        this.getBusinessStats()
        this.getBusinessstatsOfToday()
        this.getProjectStatsByDate()
        this.getBusinessStatsCurrentMonth()
    }

  

    render() {
        let { projectStats, callerStats, bizStats, isLoading, bizStatsCurr, isBusinessInsights } = this.state
        if (!isLoading) {
            return (
                <div className="tring-dashboard-wrapper">

                    {projectStats.length > 0 ? this.renderProjectInsights(projectStats) : this.renderProjectInsightsWithoutData()}
                    {callerStats.length > 0 ? this.renderCallerInsights(callerStats) : this.renderCallerInsightsWithoutData()}
                    {isBusinessInsights ? this.renderBusinessInsights() : this.renderBusinessInsightsWithoutData()}
                    {/* {bizStats.length > 0 || bizStatsCurr.length > 0 ? this.renderBusinessInsights(bizStats, bizStatsCurr) : this.renderBusinessInsightsWithoutData()} */}
                </div>
            )
        } else
            return <Loader />
    }


    renderProjectInsights = (projectStats) => {
        return (
            <div className="section-wrapper" >
                <div className="heading"> Project Insights</div>
                <div className="card-section">
                    {
                        projectStats.map((stat, i) => {
                            if (stat.name === "totalLiveProjects") {
                                return (
                                    <div className="info-wrap" key={i}>
                                        {this.state.totalLiveProjects ? <div className="info" onClick={() => this.closeAll()}><p>{stat.description}</p> </div> : null}
                                        <div className="card">

                                            <div className="card-heading">Total Live Projects
                                            <div className="info-img" onClick={() => this.showDesc(stat.name)}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                                            </div>
                                            <div className="stat">{stat.count} </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    }

    renderProjectInsightsWithoutData = () => {
        return (
            <>
                <div className="section-wrapper" >
                    <div className="heading"> Project Insights</div>
                    <div className="card-section">
                        <div className="info-wrap">
                            {this.state.totalLiveProjects ? <div className="info" onClick={() => this.closeAll()}><p>Total projects where the end date has not yet passed</p> </div> : null}
                            <div className="card">
                                <div className="card-heading">Total Live Projects
                                <div className="info-img" onClick={() => this.showDesc('totalLiveProjects')}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                                </div>
                                <div className="stat">NA</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    showDesc = (statevariable) => {
        let { totalLiveProjects, totalCallAttempts, pendingCalls, certifiedCallers, assignedCallersCount
            , idleCallersCount, callersActiveToday, callersCheckedInToday, callersCompletedWHTargetToday, totalCredits, totalCreditsmtd, totalDurationInMinutes,
            totalDurationInMinutesmtd, monthlyMinutes, totalOverallCredits, monthlyCredits, dailyMinutes
        } = this.state;
        switch (statevariable) {
            case "totalLiveProjects":
                this.setState({ totalLiveProjects: !totalLiveProjects })
                break;
            case "totalCallAttempts":
                this.setState({ totalCallAttempts: !totalCallAttempts })
                break;
            case "pendingCalls":
                this.setState({ pendingCalls: !pendingCalls })
                break;
            case "certifiedCallers":
                this.setState({ certifiedCallers: !certifiedCallers })
                break;
            case "assignedCallersCount":
                this.setState({ assignedCallersCount: !assignedCallersCount })
                break;
            case "idleCallersCount":
                this.setState({ idleCallersCount: !idleCallersCount })
                break;
            case "callersActiveToday":
                this.setState({ callersActiveToday: !callersActiveToday })
                break;
            case "totalCredits":
                this.setState({ totalCredits: !totalCredits })
                break;
            case "callersCheckedInToday":
                this.setState({ callersCheckedInToday: !callersCheckedInToday })
                break;
            case "callersCompletedWHTargetToday":
                this.setState({ callersCompletedWHTargetToday: !callersCompletedWHTargetToday })
            case "totalCreditsmtd":
                this.setState({ totalCreditsmtd: !totalCreditsmtd })
                break;
            case "totalDurationInMinutes":
                this.setState({ totalDurationInMinutes: !totalDurationInMinutes })
                break;
            case "totalDurationInMinutesmtd":
                this.setState({ totalDurationInMinutesmtd: !totalDurationInMinutesmtd })
                break;
            case "monthlyMinutes":
                this.setState({ monthlyMinutes: !monthlyMinutes })
                break;
            case "monthlyCredits":
                this.setState({ monthlyCredits: !monthlyCredits })
                break;
            case "dailyMinutes":
                this.setState({ dailyMinutes: !dailyMinutes })
                break;
            case "totalOverallCredits":
                this.setState({ totalOverallCredits: !totalOverallCredits })
                break;
            default:
            // code block
        }
    }

    getIndexOfArrayElement = (array, key) => {
        return array.findIndex(value => value.name == key)
    }
    renderCallerInsights = (callerStats) => {
        const getIndexOfAssignedCallersCount = this.getIndexOfArrayElement(callerStats,'assignedCallersCount')
        const getIndexOfCallersActiveToday = this.getIndexOfArrayElement(callerStats,'callersActiveToday')
        const getIndexOfCallersCheckedInToday = this.getIndexOfArrayElement(callerStats,'callersCheckedInToday')
        const getIndexOfCallersCompletedWHTargetToday = this.getIndexOfArrayElement(callerStats, 'callersCompletedWHTargetToday')
        
        let reorderedCallerStats = [
            callerStats[getIndexOfAssignedCallersCount],
            callerStats[getIndexOfCallersCheckedInToday],
            callerStats[getIndexOfCallersActiveToday],
            callerStats[getIndexOfCallersCompletedWHTargetToday]
        ]
        
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;
        return (
            <div className="section-wrapper" >
                <div className="heading"> Caller Insights</div>
                <div className="card-section">
                    {
                        reorderedCallerStats.map((stat, i) => {
                            if (stat.name === "assignedCallersCount") {
                                return <div className="info-wrap" key={i}>
                                    {this.state.assignedCallersCount ? <div className="info" onClick={() => this.closeAll()}><p>{stat.description}</p> </div> : null}
                                    <div className="card">
                                        <div className="card-heading">Callers assigned
                                              <div className="info-img" onClick={() => this.showDesc(stat.name)}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                                        </div>
                                        <div className="stat">{stat.count} </div>
                                    </div>
                                </div>
                            }

                            if (stat.name === "callersCheckedInToday") {
                                return <div className="info-wrap" key={i}>
                                    {this.state.callersCheckedInToday ? <div className="info" onClick={() => this.closeAll()}><p>{stat.description}</p> </div> : null}
                                    <div className="card">
                                        <div className="card-heading">Callers checked-in today
                                              <div className="info-img" onClick={() => this.showDesc(stat.name)}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                                        </div>
                                        <div className="stat">{stat.count} </div>
                                    </div>
                                </div>
                            }

                            if (stat.name === "callersActiveToday") {
                                return <div className="info-wrap" key={i}>
                                    {this.state.callersActiveToday ? <div className="info" onClick={() => this.closeAll()}><p>{stat.description}</p> </div> : null}
                                    <div className="card">
                                        <div className="card-heading">Callers active today
                                              <div className="info-img" onClick={() => this.showDesc(stat.name)}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                                        </div>
                                        <div className="stat">{stat.count} </div>
                                    </div>
                                </div>
                            }

                            if (stat.name === "callersCompletedWHTargetToday") {
                                return <div className="info-wrap" key={i}>
                                    {this.state.callersCompletedWHTargetToday ? <div className="info" onClick={() => this.closeAll()}><p>{stat.description}</p> </div> : null}
                                    <div className="card">
                                        <div className="card-heading">Callers completed target today
                                              <div className="info-img" onClick={() => this.showDesc(stat.name)}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                                        </div>
                                        <div className="stat">{stat.count} </div>
                                    </div>
                                </div>
                            }
                        })
                    }
                </div>

            </div>
        )
    }

    renderCallerInsightsWithoutData = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;
        return (
            <div className="section-wrapper" >
                <div className="heading"> Caller Insights</div>
                <div className="card-section">
                    <div className="info-wrap">
                        {this.state.assignedCallersCount ? <div className="info" onClick={() => this.closeAll()}><p>Callers who made at least 1 call today</p> </div> : null}
                        <div className="card">
                            <div className="card-heading">Callers assigned
                            <div className="info-img" onClick={() => this.showDesc('assignedCallersCount')}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                            </div>
                            <div className="stat">NA </div>
                        </div>
                    </div>

                    <div className="info-wrap">
                        {this.state.callersCheckedInToday ? <div className="info" onClick={() => this.closeAll()}><p>Count of callers checked in today</p> </div> : null}
                        <div className="card">
                            <div className="card-heading">Callers checked-in today
                            <div className="info-img" onClick={() => this.showDesc('callersCheckedInToday')}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                            </div>
                            <div className="stat">NA </div>
                        </div>
                    </div>

                    <div className="info-wrap">
                        {this.state.callersActiveToday ? <div className="info" onClick={() => this.closeAll()}><p>Certified callers who are currently not assigned to any project</p> </div> : null}
                        <div className="card">
                            <div className="card-heading">Callers active today
                            <div className="info-img" onClick={() => this.showDesc('callersActiveToday')}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                            </div>
                            <div className="stat">NA </div>
                        </div>
                    </div>

                    <div className="info-wrap">
                        {this.state.callersCompletedWHTargetToday ? <div className="info" onClick={() => this.closeAll()}><p>Count of callers Completed Working Hours Target</p> </div> : null}
                        <div className="card">
                            <div className="card-heading">Callers completed target today
                            <div className="info-img" onClick={() => this.showDesc('callersCompletedWHTargetToday')}> <img src={require("../../assets/images/black-info-icon.svg")} /></div>
                            </div>
                            <div className="stat">NA </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    closeAll = () => {
        this.setState({
            totalLiveProjects: false,
            pendingCalls: false,
            totalCallAttempts: false,
            certifiedCallers: false,
            assignedCallersCount: false,
            idleCallersCount: false,
            callersActiveToday: false,
            callersCheckedInToday:false,
            callersCompletedWHTargetToday: false,
            totalCredits: false,
            totalCreditsmtd: false,
            totalDurationInMinutes: false,
            totalDurationInMinutesmtd: false,
            totalOverallCredits: false,
            monthlyCredits: false,
            monthlyMinutes: false,
            dailyMinutes: false
        })
    }
    renderBusinessInsights = () => {
        //  params to fn: bizStats, bizStatsCurr
        // let businessStats = [];
        // let businessStatsMTD = [];
        // let uniq = bizStats.length > 0 ? [...new Set(bizStats)] : null;
        // let uniqCurr = bizStatsCurr.length > 0 ? [...new Set(bizStatsCurr)] : null
        // businessStats = uniq.slice(0,1);
        // businessStatsMTD = uniq.slice(2,3);

        let {isdailyMinutes, istotalOverallCredits, ismonthlyCredits, ismonthlyMinutes} = this.state

        return(
            <div className="section-wrapper">
                <div className="heading"> Business Insights</div>
                <div className="card-section">

                <div className="info-wrap">
                {this.state.totalOverallCredits ? <div className="info" onClick={() => this.closeAll()}><p>{istotalOverallCredits ? istotalOverallCredits.description : 'NA'}</p> </div> : null}
                <div className="card" >
                <div className="card-heading" >Credits
                <div className="info-img" onClick={() => this.showDesc(istotalOverallCredits ? istotalOverallCredits.name : 'NA')}>
                <img src={require("../../assets/images/black-info-icon.svg")} />
                </div>
                </div>
                <div className="stat-wrapper">
                <div className="stat" >{istotalOverallCredits ? istotalOverallCredits.count : 'NA'}
                <div className="date">Total credits
                </div>
                </div>
                </div>
                </div>
                </div>

                <div className="info-wrap">
                {this.state.monthlyMinutes ? <div className="info" onClick={() => this.closeAll()}><p>{ismonthlyMinutes ? ismonthlyMinutes.description : 'NA'}</p> </div> : null}
                <div className="card" >
                <div className="card-heading" >Minutes
                <div className="info-img" onClick={() => this.showDesc(ismonthlyMinutes ? ismonthlyMinutes.name : 'NA')}>
                <img src={require("../../assets/images/black-info-icon.svg")} />
                </div>
                </div>
                <div className="stat-wrapper">
                <div className="stat" >{ismonthlyMinutes ? ismonthlyMinutes.count : 'NA'}
                <div className="date">Total minutes
                </div>
                </div>
                </div>
                </div>
                </div>
                
                <div className="info-wrap">
                {this.state.monthlyCredits ? <div className="info" onClick={() => this.closeAll()}><p>{ismonthlyCredits ? ismonthlyCredits.description : 'NA'}</p> </div> : null}
                <div className="card" >
                <div className="card-heading" >Credits
                <div className="info-img" onClick={() => this.showDesc(ismonthlyCredits ? ismonthlyCredits.name : 'NA')}>
                <img src={require("../../assets/images/black-info-icon.svg")} />
                </div>
                </div>
                <div className="stat-wrapper">
                <div className="stat" >{ismonthlyCredits ? ismonthlyCredits.count : 'NA'}
                <div className="date">Total credits
                </div>
                </div>
                </div>
                </div>
                </div>

                <div className="info-wrap">
                {this.state.dailyMinutes ? <div className="info" onClick={() => this.closeAll()}><p>{isdailyMinutes ? isdailyMinutes.description : 'NA'}</p> </div> : null}
                <div className="card" >
                <div className="card-heading" >Minutes
                <div className="info-img" onClick={() => this.showDesc(isdailyMinutes ? isdailyMinutes.name : 'NA')}>
                <img src={require("../../assets/images/black-info-icon.svg")} />
                </div>
                </div>
                <div className="stat-wrapper">
                <div className="stat" >{isdailyMinutes ? isdailyMinutes.count : 'NA'}
                <div className="date">Total minutes
                </div>
                </div>
                </div>
                </div>
                </div>

                </div>
            </div>
        )
    }

    renderBusinessInsightsWithoutData = () => {
        let businessData = [
            {
                name: 'totalOverallCredits',
                description: 'Total credits sold since Aug 01 2020'
            },
            {
                name: 'monthlyMinutes',
                description: 'Total Minutes spoken this month'

            },
            {
                name: 'monthlyCredits',
                description: 'Total Credits sold this month'
            },
            {
                name: 'dailyMinutes',
                description: 'Total Minutes spoken today'
            }

        ]
        return (
            <div className="section-wrapper" >
                <div className="heading"> Business Insights</div>
                <div className="card-section">
                {
                    businessData.map((biz,i) => {
                        if(biz.name==="totalOverallCredits")
                        {
                            return(
                            <div className="info-wrap" key={i}>
                            {this.state.totalOverallCredits? <div className="info" onClick={()=>this.closeAll()}><p>{biz.description}</p> </div>:null}
                            <div className="card" >
                            <div className="card-heading" >Credits
                            <div className="info-img" onClick={()=>this.showDesc(biz.name)}>
                                <img src={require("../../assets/images/black-info-icon.svg")}/>
                            </div> 
                            </div>
                            <div className="stat-wrapper">
                            {/* Give id="card-line" for UI */}
                            <div className="stat" >NA
                                <div className="date">Total credits
                                </div>
                            </div> 
                            </div>
                            </div>
                            </div>
                            )
                        }


                        if(biz.name==="monthlyMinutes"){
                        return (
                           <div className="info-wrap" key={i}>
                            {this.state.monthlyMinutes? <div className="info" onClick={()=>this.closeAll()}><p>{biz.description}</p> </div>:null}
                            <div className="card">
                            <div className="card-heading">Minutes 
                            <div className="info-img" onClick={()=>this.showDesc(biz.name)}> <img src={require("../../assets/images/black-info-icon.svg")}/></div>
                            </div>
                            <div className="stat-wrapper">
                            <div className="stat" >NA

                                <div className="date">Total minutes
                                </div>
                            </div>
                            </div>
                            </div>
                           </div>
                        )
                        }

                        if(biz.name==="monthlyCredits")
                        {
                            return(
                            <div className="info-wrap" key={i}>
                            {this.state.monthlyCredits? <div className="info" onClick={()=>this.closeAll()}><p>{biz.description}</p> </div>:null}
                            <div className="card" >
                            <div className="card-heading" >Credits
                            <div className="info-img" onClick={()=>this.showDesc(biz.name)}>
                                <img src={require("../../assets/images/black-info-icon.svg")}/>
                            </div> 
                            </div>
                            <div className="stat-wrapper">
                            {/* Give id="card-line" for UI */}
                            <div className="stat" >NA
                                <div className="date">Total credits
                                </div>
                            </div> 
                            </div>
                            </div>
                            </div>
                            )
                        }

                        if(biz.name==="dailyMinutes"){
                            return (
                               <div className="info-wrap" key={i}>
                                {this.state.dailyMinutes? <div className="info" onClick={()=>this.closeAll()}><p>{biz.description}</p> </div>:null}
                                <div className="card">
                                <div className="card-heading">Minutes 
                                <div className="info-img" onClick={()=>this.showDesc(biz.name)}> <img src={require("../../assets/images/black-info-icon.svg")}/></div>
                                </div>
                                <div className="stat-wrapper">
                                <div className="stat" >NA
    
                                    <div className="date">Total minutes
                                    </div>
                                </div>
                                </div>
                                </div>
                               </div>
                            )
                            }
                    })
                }
            </div>
            </div>
        )
    }
}
export default TringDashboard