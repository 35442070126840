import React, {useEffect, useState} from 'react'
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl'
import { cloneDeep, set } from 'lodash';
import {TrashIcon, InfoIcon} from "../../assets/images"
import AttemptInput from './AttemptInput';
import CustomTooltip from '../utilities/ToolTip';
import { Dropdown, MenuItem } from "react-bootstrap";

const distributionOptions = [
    {
        value: 'roundrobin',
        label: 'Legacy Distribution (attempts)'
    },
    {
        value: 'cadence',
        label: 'Cadence Distribution (TAT)'
    }
]

const prioritizationOptions = [
    {
        value: 'cadence-queued-first',
        label: 'Scheduled Leads (Default)',
        desc: 'Cadence will be followed strictly, scheduled leads will take precedence over fresh'
    },
    {
        value: 'cadence-fresh-first',
        label: 'Fresh Leads',
        desc: 'Fresh leads will take priority for 1 attempt before scheduled leads'
    }
]

const defaultAttempt = {
    number: 1,
    value: 0,
    type: 'min',
    edit: false,
    delete: false
}

const defaultOnwards = {
    number: "onwards",
    value: 1,
    type: 'min',
    edit: false,
    delete: false
}

const LeadDistribution = (props) => {
    const {leadDistribution} = props || {}
    const [distributionData, setDistributionData] = useState({
        "style": leadDistribution ? leadDistribution.style : "roundrobin"
        
    })

    const [cadencePriority, setCadencePriority] = useState({})
    const [priortisationMenuValue, setPriortisationValue] = useState(prioritizationOptions[0].label)


    useEffect(()=>{
        if(leadDistribution && leadDistribution.priority){
            setPriortisationValue(prioritizationOptions.find(item => item.value  ===  leadDistribution.priority).label)
        }
    },[leadDistribution])

    const formatTimeType = (value) => {
        const minutes = value
        if(minutes >= 1440){
            // 1440 minutes = 1 day
            const days = Math.floor(minutes / 1440);
            const remainingMinutes = minutes % 1440;
            if(remainingMinutes){
                return "min"
            }else{
                return "day"
            }
        }else if(minutes >= 60){
            // 60 minutes = 1 hour
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            if(remainingMinutes){
                return "min"
            }else{
                return "hour"
            }
        }else{
            return "min"
        }
    }

    const formatTime = (minutes) => {
        if(minutes >= 1440){
            // 1440 minutes = 1 day
            const days = Math.floor(minutes / 1440);
            const remainingMinutes = minutes % 1440;
            if(remainingMinutes){
                return minutes
            }else{
                return days
            }
        }else if(minutes >= 60){
            // 60 minutes = 1 hour
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            if(remainingMinutes){
                return minutes
            }else{
                return hours
            }
        }else{
            return minutes
        }
    }

    const [onwards, setOnwards] = useState(
        leadDistribution && leadDistribution.style == 'cadence' && leadDistribution.cadence ?  [{
            "number": "onwards",
            "value": formatTime(leadDistribution.cadence["onwards"]),
            type: formatTimeType(leadDistribution.cadence["onwards"]),
            edit: false,
            delete: false
        }] : [defaultOnwards]
    )

    const formatCadenceAttempts = () => {
        let result = Object.entries(leadDistribution.cadence).filter(([key,value]) => key !== 'onwards')
        .map(([key, value]) => {
            let obj = {}
            obj['number'] = key
            obj['value'] = formatTime(value)
            obj['type'] = `${formatTimeType(value)}`
            return obj
        })
        return result
    }
    const [cadenceAttempts, setCadenceAttempts] = useState(leadDistribution && leadDistribution.style == 'cadence' && leadDistribution.cadence ? formatCadenceAttempts : [defaultAttempt, defaultOnwards])

    useEffect(()=>{
        if(leadDistribution && leadDistribution.style == 'cadence'){
            setDistributionData({
                style: leadDistribution.style
            })
            setCadenceAttempts(formatCadenceAttempts())
            setOnwards([{
                "number": "onwards",
                "value": formatTime(leadDistribution.cadence["onwards"]),
                type: formatTimeType(leadDistribution.cadence["onwards"]),
                edit: false,
                delete: false
            }])
        }
    },[leadDistribution])

    useEffect(()=>{
        props.updateLeadDistribution(cadenceAttempts, onwards)
    },[cadenceAttempts, onwards])



    const addAttemptField = () => {
        let onlyUserDefined = cadenceAttempts.filter(item => item.number !== 'onwards')
        let tempData = cloneDeep(onlyUserDefined)
        tempData.push({
            number: `${onlyUserDefined.length + 1}`,
            value: "",
            type: 'min'
        })
        setCadenceAttempts(tempData)
    }

    const removeAttempt = (idx) => {
        let tempData = cloneDeep(cadenceAttempts)
        tempData.splice(idx, 1)
        setCadenceAttempts(tempData)
    }

  

    const updateForm = (inputValue, inputType, idx, type) => {
        if(type){
            let tempData = cloneDeep(onwards)
            tempData[idx][inputType] = inputValue
            setOnwards(tempData)
        }else{
            let tempData = cloneDeep(cadenceAttempts)
            tempData[idx][inputType] = inputValue
            setCadenceAttempts(tempData)
        }
    }

    const attemptInput = (item, idx) => (
        <div>
            <div className='attempt-count'>Attempt&nbsp;{item.number}</div>
            <div className='attempt-input'>
                <AttemptInput item={item} idx={idx} updateForm={updateForm}/>
                <img src={TrashIcon} className={item.number == 1? 'delete-attempt disabled' : 'delete-attempt'} onClick={()=> item.number == 1 ? null : removeAttempt(idx)}/>
            </div>
        </div>
        
    )

    return(
        <div className='lead-distribution-wrapper'>
            <FormControl 
            componentClass="select" 
            placeholder="select" 
            onChange={(e) => {
                setDistributionData({
                ...distributionData,
                style: e.target.value
                })
            props.updateLeadDistribution(cadenceAttempts, onwards, e.target.value)
            }}
            value={distributionData.style}
            >
            {
                distributionOptions.map(item => (
                    <option value={item.value}>{item.label}</option>
                ))
            }
            </FormControl>
  
           {distributionData.style !== 'roundrobin' ? <div>
            <FormGroup>
            <ControlLabel>Lead Prioritization</ControlLabel>
            {<Dropdown onSelect={(e) => {
                setCadencePriority({
                    ...cadencePriority,
                    priority: e
                })
                const newSelection = prioritizationOptions.find((opt) => opt.value === e).label;
                if(newSelection){
                    setPriortisationValue(newSelection)
                }
                props.updateLeadDistribution(cadenceAttempts, onwards, distributionData.style, e)
            }} className='priority-menu'>
                <Dropdown.Toggle id="dropdown-basic">
                {priortisationMenuValue}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {prioritizationOptions.map((option, idx) => (
                    <MenuItem  key={option.value} eventKey={option.value} onClick={() => {
                        setCadencePriority({
                            ...cadencePriority,
                            priority: option.value
                        })
                        const newSelection = prioritizationOptions.find((opt) => opt.value === option.value).label;
                        if(newSelection){
                            setPriortisationValue(newSelection)
                        }
                        props.updateLeadDistribution(cadenceAttempts, onwards, distributionData.style, option.value)
                    }}>
                        <CustomTooltip placement={'right'} component={<div>{option.label} <img src={InfoIcon} style={{marginLeft: '2px', width: '15px'}}/></div>}
                        description={option.desc} />
                    </MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>}
            </FormGroup>


           <div className='cadence-attempts-wrapper'>
            <div className='attempts_section'>
                {
                    cadenceAttempts.map((item, idx) => (
                            item.number !== 'onwards' ? <div className='attempt-grid-item'>
                                {attemptInput(item, idx)}
                            </div> : null
                    ))
                }
            </div>
            <div className='onward-wrapper'>
                <div>
            <div className='onward-count'>
                <div>Further attempts</div>
                <CustomTooltip placement={'right'} component={<img src={InfoIcon} />}
                description={'Any further extra attempts added will have a cadence as set in this field'} />
            </div>
            {
                onwards && onwards.map((item, idx) => <div className='onward-input'>
                    <AttemptInput item={item} idx={idx} updateForm={updateForm}/></div>)
            }
            </div>
            </div>
            
            <div className='add-attempts'onClick={() => addAttemptField()}>+ Add Attempt</div>
            </div> 
            </div> : null}
        </div>
    )
}

export default React.memo(LeadDistribution)