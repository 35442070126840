import React, { useState, useRef } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import FrappButton from '../../utilities/FrappButton';
import Popup from '../../../uicomponents/popup';

const QuitConfirmationDialog = ({ isOpen, onClose, onConfirm }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [remark, setRemark] = useState('');
    const [showRemarkDropdown, setShowRemarkDropdown] = useState(false);
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
    const taskUploadBtn = useRef(null);

    const handleThumbsUp = () => {
        setSelectedOption('thumbs-up');
        setShowRemarkDropdown(false);
        setRemark('');
        setSubmitBtnDisabled(false);
    };

    const handleThumbsDown = () => {
        setSelectedOption('thumbs-down');
        setShowRemarkDropdown(true);
        setSubmitBtnDisabled(true);
    };

    const handleRemarkChange = (val) => {
        setRemark(val.value);
        setSubmitBtnDisabled(false); 
    };

    const handleSubmit = () => {
        onConfirm(selectedOption, remark);
        onClose();
    };

    const submitDropLead = () => {
        if (taskUploadBtn.current) {
            taskUploadBtn.current.startLoading();
        }
        setSubmitBtnDisabled(true);
        handleSubmit();
    };

    const remarksOptions = [
        { label: 'Poor attendance', value: 'poor_attendance' },
        { label: 'Behavioural Issue', value: 'behavioural_issue' },
        { label: 'Unresponsive to Calls/Emails', value: 'unresponsive_calls' },
        { label: 'Performance Concerns', value: 'performance_concerns' },
        { label: 'Other', value: 'other' },
    ];

    return (
        <Popup show={isOpen} closePopup={onClose}>
            <div
                style={{
                    backgroundColor: 'white',
                    padding: '15px',
                    borderRadius: '8px',
                    width: '450px',
                    textAlign: 'center',
                    position: 'relative',
                }}
            >

            <h2 style={{ 
                textAlign: 'left', 
                margin: '0',
                paddingBottom: '10px', 
                borderBottom: '1px solid #ccc', 
                fontWeight: 'bolder',
                width: '100%', 
                marginTop:'0px'
            }}>
                Quit
            </h2>
            <br/>
                <h4>How did the caller perform?</h4>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '20px',
                        margin: '20px 0',
                    }}
                >
                    <button
                        style={{
                            background: selectedOption === 'thumbs-up' ? '#228B22' : 'transparent',
                            border: selectedOption === 'thumbs-up' ? '2px solid #228B22' : '2px solid #ccc',
                            borderRadius: '50%',
                            padding: '10px',
                            cursor: 'pointer',
                            fontSize: '20px',
                            color: selectedOption === 'thumbs-up' ? 'white' : '#000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '50px',
                            height: '50px',
                            transition: 'background 0.3s, color 0.3s',
                        }}
                        onClick={handleThumbsUp}
                    >
                        <FontAwesomeIcon icon={faThumbsUp} />
                    </button>
                    <button
                        style={{
                            background: selectedOption === 'thumbs-down' ? 'red' : 'transparent',
                            border: selectedOption === 'thumbs-down' ? '2px solid red' : '2px solid #ccc',
                            borderRadius: '50%',
                            padding: '10px',
                            cursor: 'pointer',
                            fontSize: '20px',
                            color: selectedOption === 'thumbs-down' ? 'white' : '#000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '50px',
                            height: '50px',
                            transition: 'background 0.3s, color 0.3s',
                        }}
                        onClick={handleThumbsDown}
                    >
                        <FontAwesomeIcon icon={faThumbsDown} />
                    </button>
                </div>

                {showRemarkDropdown && (
                    <>
                        <h5 style={{ textAlign: 'left' }}>Select Remark</h5>
                        <div style={{ margin: '20px 0', textAlign: 'left', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                            <Dropdown
                                options={remarksOptions}
                                onChange={handleRemarkChange}
                                value={remark}
                                placeholder="Select"
                            />
                        </div>
                    </>
                )}

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '20px',
                    }}
                >
                    <FrappButton
                        className="submit"
                        id="new-btn"
                        disabled={submitBtnDisabled}
                        ref={taskUploadBtn}
                        handler={submitDropLead}
                    >
                        Submit
                    </FrappButton>
                </div>
            </div>
            <ToastContainer />
        </Popup>
    );
};

export default QuitConfirmationDialog;