import React, {useState, useEffect} from 'react'
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import Table from '../../../uicomponents/table/Table'
import {getIdleCallers, matchTelecaller, getCallerJobHistory} from './service'
import { getCallerJobRemark } from '../../hiring/service';
import useApi from '../../../hooks/useApi'
import FrappButton from '../../../components/utilities/FrappButton'
import Popup from '../../../uicomponents/popup'
import JobsHistory from './jobHistory'
import AvailableJobs from './availableJobsTable'
import CallerInfo from './callerInfo'
import {UserIcon, TrackHistory} from '../../../assets/images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader';

const IdleTable = () => {
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1);

    const getIdleCallerapi = useApi(getIdleCallers)
    const matchTelecallerApi = useApi(matchTelecaller)
    const getCallerJobHistoryApi = useApi(getCallerJobHistory); 
    const getCallerJobRemarkApi = useApi(getCallerJobRemark);

    const [showCallerInfo, setShowCallerInfo] = useState(false)
    const [showCallerHistory, setShowCallerHistory] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')
    const [showAvailableJobs, setShowAvailableJobs] = useState(false)
    const [showRemarkPopup, setShowRemarkPopup] = useState(false);

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getIdleCallerapi.request(skip,limit,sort,search)
    },[currentPage, sort, search])

    const formatCallerStatus = (status) => {
        switch (status) {
            case 'looking_to_work':
                return `Looking for a job`
            case 'working':
                return `Working`
            case 'on_a_break':
                return `On a break`
            case 'quit':
                return `Quit`
            default:
                return status || 'NA'
        }
    }


    const columns = [
        {
            Header: 'Name',
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            disableSortBy: true
        },
        {
            Header: 'Mobile',
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Email',
            accessor: "email",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Caller status',
            accessor: "workStatus",
            Cell: ({value}) => (value ? formatCallerStatus(value) : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Mins spoken',
            accessor: "totalWorkMinutes",
            Cell: ({value}) => ( value ? value : 0),
        },
        // {
        //     Header: 'Last call made',
        //     accessor: "lastCallMade",
        //     Cell: ({value}) => (
        //        value !== ('NA' || '') ? moment(value).fromNow() : value
        //     )
        //     Cell: ({value}) => (value ? moment(value).fromNow() : 'NA') 
        // },
        {
            Header: 'Last seen',
            accessor: "lastSeen",
            Cell: ({value}) => (value ? moment(value).fromNow() : 'NA')
        },
        {
            Header: 'Caller info',
            Cell: (cell) => (
                <img src={UserIcon} alt='Caller info'
                onClick={()=>{
                    setShowCallerInfo(true)
                    setSelectedRowData(cell.row.original)
                }}
                />
            ),
            disableSortBy: true

        },
        {
            Header: 'Job History',
            Cell: (cell) => (
                <img src={TrackHistory} alt='Job History'
                onClick={()=>{
                    setShowCallerHistory(true)
                    setSelectedRowData(cell.row.original)
                }}
                />
            ),
            disableSortBy: true
        },
        {
            Header: 'Remark',
            accessor: 'remark',
            Cell: ({ row }) => {
                return (
                    <FontAwesomeIcon
                        icon={faMessage}
                        className={styles.remark_icon}
                        onClick={() => {
                            setSelectedRowData(row.original); 
                            getCallerJobHistoryApi.request({ user: row.original.id, status: "quit" }); 
                            setShowRemarkPopup(true); 
                        }}
                    />
                );
            },
            disableSortBy: true,
        },
        
        {
            Header: '',
            accessor: "action",
            Cell: (cell) => (
            <div className={styles.blue_link} onClick={() =>{
                setShowAvailableJobs(true)
                setSelectedRowData(cell.row.original)
            }}>
            ASSIGN JOB
            </div>
            ),
            disableSortBy: true
        }
    ]

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const filterChange = (d) => {
        setSearch(d)
        setCurrentPage(1)
    }

    const {data, total} = getIdleCallerapi.data || {}
    const {loading} = getIdleCallerapi

    const renderRemarkPopup = () => {
        if (!getCallerJobHistoryApi.data) {
            return null;
        }
        let quitRemarks = [];
        getCallerJobHistoryApi.data.forEach(project => {
            if (project.statusChangeLog && project.statusChangeLog.length > 0) {
                const latestLog = project.statusChangeLog.reduce((latest, log) => {
                    const logDate = new Date(log.updatedAt);
                    const latestDate = latest ? new Date(latest.updatedAt) : null;
                    return (!latestDate || logDate > latestDate) ? log : latest;
                }, null);
    
                if (latestLog && latestLog.reason.includes("Quit") && latestLog.remark) {
                    quitRemarks.push({
                        teleproject: project.teleproject, 
                        remark: latestLog.remark, 
                        date: latestLog.updatedAt, 
                        reportedBy: latestLog.actionBy 
                    });
                }
            }
        });
        // sort the remarks by latest first 
        quitRemarks.sort((a, b) => new Date(b.date) - new Date(a.date));
        const remarkColumns = [
            {
                Header: 'Project ID',
                accessor: 'teleproject',
                disableSortBy: true
            },
            {
                Header: 'Remark',
                accessor: 'remark',
                disableSortBy: true
            },
            {
                Header: 'Date of Remark',
                accessor: 'date',
                Cell: ({cell}) => {
                    const {date: value} = cell.row.original
                    return <div>{value ? 
                        <span>{moment(value).format("DD/MM/YYYY")} | {moment(value).format('hh:mm A')}</span>
                        : 'N/A'
                    }</div>
                },
                // Cell: ({ cell }) => {
                //     const value = cell.row.original
                //     return <div>{value ? 
                //         moment(value).format("DD/MM/YYYY") | moment(value).format('hh:mm A')
                //         : 'N/A'
                //     }</div>
                // },
                disableSortBy: true
            },
            {
                Header: 'Reported By',
                accessor: 'reportedBy',
                disableSortBy: true
            }
        ];
        return (
            <Popup
                show={showRemarkPopup}
                closePopup={() => {
                    setSelectedRowData("");
                    getCallerJobRemarkApi.reset();
                    setShowRemarkPopup(false);
                }}
                size="md" 
                heading={
                    <div>
                        <div>Remark</div>
                        <div className={styles.render_remark_divider}></div>
                    </div>
                }
                popupContent={
                    getCallerJobHistoryApi.loading ? (
                        <div style={{ textAlign: "center" }}>
                            <ThreeDotLoader />
                        </div>
                    ) : quitRemarks.length > 0 ? (
                        <Table
                            columns={remarkColumns}
                            data={quitRemarks}
                            totalCount={quitRemarks.length}
                            limit={10} 
                            currentPage={1} 
                            setCurrentPage={() => {}} 
                            loading={false}
                            sortChange={() => {}} 
                            filterChange={() => {}} 
                            isGlobalFilter={false}
                            type = 'client'
                        />
                    ) : (
                        <div className={styles.render_remark_fallback}>
                            <img
                                src="https://play-lh.googleusercontent.com/ue1pf33HUuVLlZ_Xiysai5Akr6yugLtU502nZPFtibDFUVfkLG-K_aH4qNOHrdGx2vh8"
                                alt="No Remark Available"
                            />
                            <p className={styles.fallback_text}>No remark added</p>
                        </div>
                    )
                }
            />
        );
    };
    const renderCallerInfo = () => {
        const { firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showCallerInfo}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerInfo(false)
            }}
            size='sm'
            heading={`Caller profile and information of ${firstname} ${lastname}`}
            popupContent = {<CallerInfo rowData={selectedRowData} callerPreference={true}/>}
            />
        )
    }

    const renderJobHistory = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showCallerHistory}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerHistory(false)
            }}
            size='lg'
            heading={`Job history of ${firstname} ${lastname}`}
            popupContent = {<JobsHistory rowData={selectedRowData}/>}
            />
        )
    }

    const closeAvailableJobs = () => {
        setSelectedRowData('')
        setShowAvailableJobs(false)
        matchTelecallerApi.reset()
    }

    useEffect(()=>{
        if(matchTelecallerApi && matchTelecallerApi.data && matchTelecallerApi.data.data.status == 200){
            let findIndex = data.findIndex(user => user.id == selectedRowData.id)
            if(findIndex !== -1){
                data.splice(findIndex, 1);
            }
            toast(matchTelecallerApi.data.data.msg)
            setTimeout(() => {
                closeAvailableJobs()
            }, 2000);
        }

        if(matchTelecallerApi && matchTelecallerApi.error){
            toast(matchTelecallerApi.error.msg)
        }
    },[matchTelecallerApi.data, matchTelecallerApi.error])

    const renderMatchJobs = () => {
        let projectData = ''


        const matchToJob = () => {
            const payload = {
                teleproject: projectData.id,
                user: selectedRowData.id
            }
            matchTelecallerApi.request(payload)
        }

        const ActionBtns = () => {
            return <FrappButton className="submit" id="new-btn" handler={matchToJob}>MATCH</FrappButton>
        }
        
        const passProjectSelected = (data) => {
            projectData = data
        }

        return(
            <Popup
            show={showAvailableJobs}
            closePopup={()=>{
                closeAvailableJobs()
            }}
            size='lg'
            heading={'Jobs currently open for hiring'}
            popupContent = {<AvailableJobs userData={selectedRowData} passProjectSelected={passProjectSelected}/>}
            popupFooter = {<ActionBtns/>}
            footerAlign = 'right'
            />
        )
    }

    const handlePopups = () => {
        if(showCallerInfo) return renderCallerInfo()
        if(showCallerHistory) return renderJobHistory()
        if(showAvailableJobs) return renderMatchJobs()
        if (showRemarkPopup) return renderRemarkPopup();
        else
        return null
    }

    return(
        <>
        {getIdleCallerapi.error && <p>{typeof getIdleCallerapi.error == 'object' && 
        getIdleCallerapi.error !== null &&
        !Array.isArray(getIdleCallerapi.error)
        ? getIdleCallerapi.error.error || getIdleCallerapi.error.msg : getIdleCallerapi.error}</p>}
        <Table
        columns={columns}
        data={data}
        totalCount={total}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        sortChange={sortChange}
        filterChange = {filterChange}
        isGlobalFilter = {true}
        />
        {handlePopups()}
        <ToastContainer draggable={true} />
        </>
    )
}

export default IdleTable
