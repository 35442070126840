import React, { Fragment, useEffect, useState } from 'react'
import ApiActions from '../../actions/ApiActions';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import FrappButton from '../utilities/FrappButton';
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import Loader from '../utilities/Loader';

import FrappModal from '../utilities/FrappModal';
import Chart from "react-apexcharts";
import PreviewInteractiveScript from './Tools/PreviewInteractiveScript'

import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import { ToastContainer, toast } from "react-toastify";
import { Multiselect } from 'multiselect-react-dropdown';
import ProjectTitle from '../common/ProjectTitle'
import { cloneDeep } from 'lodash';
import Datepicker from "../../uicomponents/date-picker"
import ThreeDotLoader from "../utilities/ThreeDotLoader"
import CustomTooltip from '../utilities/ToolTip'
import Popup from '../../uicomponents/popup';
import ParameterWiseDefects from '../../pages/project/parameterwiseDefects';
import { QuestionMarkCircle } from '../../assets/images';

const HISTORIC_PROJECT_REPORT = 'historic_project_report'
const REGULAR_REPORT = 'project_report'

// Concluded leads distribution
const CONNECTED = 'connected'
const NOT_CONNECTED = 'not-connected'

// Inprogress leads distribution
const INPROGRESS_CONNECTED = 'inprogress-connected'


let lineChartData = {
    options: {
        chart: {
            height: '250px',
            type: "line",
            stacked: false,
            id: "basic-bar",
            toolbar: {
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                }
            }
        },
        stroke: {
            width: [4, 4]
        },
        plotOptions: {
            bar: {
                columnWidth: "10%"
            }
        },
        xaxis: {
            type: 'datetime',
            categories: [],
            tickAmount: 4
        },
        yaxis:
        {
            min: 0,
            tickAmount: 8,
        },

    },
    series: [
        {
            name: "wining outcomes",
            data: []
        },
        {
            name: "calls attempts",
            data: []
        },
        {
            name: "calls connected",
            data: []
        },
        {
            name: "unique leads connected",
            data: []
        }
    ]
}

let donutChartData = {
    series: [],
    options: {
        chartOptions: {
            labels: [],
        },
        fill: { colors: ['#CC86C1', '#FFC484', '#EE9310', '#8ADDDD', '#56A9CE', '#169999', '#838DEA', '#636466', '#2ECEA9', '#982B2B', '#8E9775', '#7B6079', '#C2B8A3', '#7F8B52', '#AAAAAA', '#28B5B5'] },
        colors: ['#CC86C1', '#FFC484', '#EE9310', '#8ADDDD', '#56A9CE', '#169999', '#838DEA', '#636466', '#2ECEA9', '#982B2B', '#8E9775', '#7B6079', '#C2B8A3', '#7F8B52', '#AAAAAA', '#28B5B5'],
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: function (value) {
                    return value + "%";
                }
            }
        },
        chart: {
            offsetX: 0,
            zoom: {
                enabled: true,
            },
            toolbar: {
                show: true,
                // offsetX: 150,
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value(%)',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    svg: {
                        filename: undefined,
                    },
                    png: {
                        filename: undefined,
                    }
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '50%'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return Math.round(val, 0) + "%"
            }
        }
    }
}

let originalStartDate, originalEndDate = ''
const currentDay = new Date()

const NewTringStatsScreen = (props) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState('')
    const [projectStats, setProjectStats] = useState({})
    const [projectStats2, setProjectStats2] = useState({})
    const [brandDetails, setBrandDetails] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [activeProject, setActiveProject] = useState(props && props.location && props.location.state ? props.location.state : '')
    const [previewScript, setPreviewScript] = useState('')

    const [emailArray, setEmailArray] = useState([])
    const [defaultStartDate, setDefaultStartDate] = useState('')
    const [defaultEndDate, setDefaultEndDate] = useState('')
    const [startDateFocussed, setStartDateFocussed] = useState(false)
    const [endDateFocussed, setEndDateFocussed] = useState(false)
    const [eachEmail, setEachEmail] = useState("")
    const [reportSent, setReportSent] = useState(false)
    const [outcomesArray, setOutcomesArray] = useState([])
    const [outcomesArrayToSend, setOutcomesArrayToSend] = useState([])
    const [scriptDocLink, setScriptDocLink] = useState('')


    //line-chart
    const [lineChartApiData, setLineChartApiData] = useState({})
    const [mainLineChartData, setMainLineChartData] = useState({})
    const [chartFilter, setChartFilter] = useState("Daily")
    const [chartStartDate, setChartStartDate] = useState(moment().subtract(6,'days').toDate())
    const [chartEndDate, setChartEndDate] = useState(moment().toDate())

    //call-stats
    const [maxDateRange, setMaxDateRange] = useState('')
    const [hideStats, setHideStats] = useState(false)

    //donut chart
    // const [donutChartData, setDonutChartData] = useState({})
    const [failCallDonutData, setFailCallDonutData] = useState({})
    const [notConnectedCallsTotal, setNotConnectedCallsTotal] = useState("")
    const [contCallDonutData, setContCallDonutData] = useState({})
    const [connectedCallsTotal, setConnectedCallsTotal] = useState("")
    const [inProgressConnDonutData, setInProgressConnDonutData] = useState({})
    const [inProgressConnCallsTotal, setInProgressConnCallsTotal] = useState("")

    const [isPreview, setIsPreview] = useState(false)

    const [isLeadStatsTriggered, setIsLeadStatsTriggered] = useState(false)
    const [isLineGrapTriggered, setIsLineGrapTriggered] = useState(false)
    const [isCallInsightsDataEmpty, setIsCallInsightsDataEmpty] = useState(false)
    const [showLoad, setShowLoad] = useState(true)
    const [reportError, setReportError] = useState({
        status: false,
        msg: ''
    })
    const [reportStartDate, setReportStartDate] = useState(moment().toDate())
    const [reportEndDate, setReportEndDate] = useState(moment().toDate())
    const [reportType, setReportType] = useState(REGULAR_REPORT)
    const [showParameter, setShowParameter] = useState(false)
    const [leadType, setLeadType] = useState({
        type: 'connected'
    })
    const [auditInfo, setAuditInfo] = useState({
        type: 'iqc',
        data: '',
        loading: true
    })
    const [statsStartDate, setStatsStartDate] = useState(moment().subtract(6,'days').toDate())
    const [statsEndDate, setStatsEndDate] = useState(moment().toDate())

    let reportModal;
    let submitBtnReport;
    let previewScriptModal;
    let apiEnabledStatsModal;
    let brandName = ''

    const handleRawChartData = (rawChartData) => {
        let dateFormattedData = addDateFormat(rawChartData);
        let fullDateData = addMissingDateObject(
            dateFormattedData[dateFormattedData.length - 1].fullDate,
            dateFormattedData[0].fullDate
        )
        let noDuplicatedata = removeDuplicatesBy(x => x.fullDate, [...dateFormattedData, ...fullDateData])
        let sortedData = sortByDate(noDuplicatedata)
        return sortedData
    }

    const addDateFormat = (rawChartData) => {
        return rawChartData.map((item) => {
            return {
                fullDate: moment(new Date(`${item.month}-${item.day}-${item.year}`)).format("MM-DD-YYYY"),
                ...item
            }
        })
    }

    const isEmptyData = (value) => {
        return value && value.series ? value.series.length !== 0 ? value.series.every((i) => i === 0) || value.series.every((i) => i === NaN) : true : true
    }

    const removeDuplicatesBy = (keyFn, array) => {
        let mySet = new Set();
        return array.filter(function (x) {
            let key = keyFn(x), isNew = !mySet.has(key);
            if (isNew) mySet.add(key);
            return isNew;
        });
    }

    const sortByDate = (arr) => {
        arr.sort(function (a, b) {
            return Number(new Date(a.fullDate)) - Number(new Date(b.fullDate));
        });

        return arr;
    }

    const groupByWeeks = (data) => {
        return data.reduce((acc, date, index) => {
            let yearWeek = `${moment(date.fullDate).year()}-${moment(date.fullDate).week()}`;
            if (!acc[yearWeek]) {
                acc[yearWeek] = [];
            }
            acc[yearWeek].push(date);
            return acc;
        }, {});
    }

    const groupByMonths = (data) => {
        return data.reduce((acc, date) => {
            // let yearWeek = `${date.year}-${date.month}`;
            let yearWeek = moment(`${date.year}-${date.month}`).format("MMM YY");
            if (!acc[yearWeek]) {
                acc[yearWeek] = [];
            }
            acc[yearWeek].push(date);
            return acc;
        }, {});
    }

    const addMissingDateObject = (startDate, endDate, steps = 1) => {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push({
                fullDate: moment(currentDate, "MM/DD/YY").format("MM-DD-YYYY"),
                year: moment(currentDate, "MM/DD/YY").year(),
                month: moment(currentDate, "MM/DD/YY").month() + 1,
                day: moment(currentDate, "MM/DD/YY").date(),
                callCounts: 0
            });
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }
        return dateArray;
    }

    // const mergeTwoArray = (arr1, arr2) => {
    //     const map = new Map();
    //     arr1.forEach(item => map.set(item.fullDate, { ...item, winningOutcomesCount: item.callCounts ? item.callCounts : 0 }));
    //     arr2.forEach(item => map.set(item.fullDate, { ...map.get(item.fullDate), ...item, callAttemptsCount: item.callCounts ? item.callCounts : 0 }));
    //     let mergedArr = Array.from(map.values());
    //     mergedArr = sortByDate(mergedArr)
    //     return mergedArr
    // }

    const mergeTwoArray = (arr1, arr2, arr3, arr4) => {
        const map = new Map();
        arr1.forEach(item => map.set(item.fullDate, { ...item, winningOutcomesCount: item.callCounts ? item.callCounts : 0 }));
        arr2.forEach(item => map.set(item.fullDate, { ...map.get(item.fullDate), ...item, callAttemptsCount: item.callCounts ? item.callCounts : 0 }));
        arr3.forEach(item => map.set(item.fullDate, { ...map.get(item.fullDate), ...item, callConnectedCount: item.callCounts ? item.callCounts : 0 }));
        arr4.forEach(item => map.set(item.fullDate, { ...map.get(item.fullDate), ...item, uniqueCallConnectedCount: item.callCounts ? item.callCounts : 0 }));
        let mergedArr = Array.from(map.values());
        mergedArr = sortByDate(mergedArr)
        return mergedArr
    }

    const returnDailyData = (data) => {
        let x_axis_arr_daily = data.map((item) => moment(item.fullDate).format("MMM Do").replace(/th|rd|nd|st/g, ""))
        let groupedWinningData = data.map((item) => item.winningOutcomesCount)
        let groupAttemptsData = data.map((item) => item.callAttemptsCount)
        let groupedCallsConnectedData = data.map((item) => item.callConnectedCount)
        let groupedUniqueLeadsConnectedData = data.map((item => item.uniqueCallConnectedCount))

        let finalData = {
            x_axis: x_axis_arr_daily,
            winning_outcomes: groupedWinningData.map(v => v === undefined ? 0 : v),
            call_attempts: groupAttemptsData.map(v => v === undefined ? 0 : v),
            calls_connected: groupedCallsConnectedData.map(v => v === undefined ? 0 : v),
            unique_leads_connected: groupedUniqueLeadsConnectedData.map(v => v === undefined ? 0 : v)
        }
        return finalData
    }

    const checkTheWeekData = (data, x_axis) => {
        let rest = cloneDeep(data)
        let restWeek = rest && rest.map(item => formateDate(item[0].fullDate))
        if(data.length !== x_axis.length){
            x_axis.forEach((week, i) => {
                // let u = data[i]
                // if(u){
                //     console.log('u', formateDate(u[0].fullDate), week)
                //     if(formateDate(u[0].fullDate) !== week){
                //         // console.log('Weeks do not match')
                //         data[i] = []
                //         data[i+1] = u
                //     }
                // }else{
                    const getIndex = restWeek.indexOf(week)
                    if(getIndex <= -1){
                        data[i] = []
                    }else{
                        data[i] = rest[getIndex]
                    }
                // }
            })
        }
        return data
    }

    const checkTheMonthData = (data, x_axis) => {
        let rest = cloneDeep(data)
        let restMonth = rest && rest.map(item => Object.keys(groupByMonths(item)).toString())
        if(data.length !== x_axis.length){
            x_axis.forEach((week, i) => {
                const getIndex = restMonth.indexOf(week)
                if(getIndex <= -1){
                    data[i] = []
                }else{
                    data[i] = rest[getIndex]
                }
            })
        }
        return data
    }

    const returnWeeklyData = (winningOutcomeData, callAttemptsData, callConnecteData, uniqueCallConnectedData, mergedData) => {
        let x_axis_arr_week = Object.values(groupByWeeks(mergedData)).map((i) => formateDate(i[0].fullDate))
        let groupedWinningData = checkTheWeekData(Object.values(groupByWeeks(winningOutcomeData)), x_axis_arr_week)
        let groupAttemptsData = checkTheWeekData(Object.values(groupByWeeks(callAttemptsData)), x_axis_arr_week)
        let groupedCallsConnectedData =  checkTheWeekData(Object.values(groupByWeeks(callConnecteData)), x_axis_arr_week)
        let groupedUniqueLeadsConnectedData = checkTheWeekData(Object.values(groupByWeeks(uniqueCallConnectedData)), x_axis_arr_week)
        let finalData = {
            x_axis: x_axis_arr_week,
            winning_outcomes: groupedWinningData.map((i) => {
                return i.reduce((n, { callCounts }) => n + callCounts, 0)
            }),
            call_attempts: groupAttemptsData.map((i) => {
                return i.reduce((n, { callCounts }) => n + callCounts, 0)
            }),
            calls_connected: groupedCallsConnectedData.map((i) => {
                return i.reduce((n, { callCounts }) => n + callCounts, 0)
            }),
            unique_leads_connected: groupedUniqueLeadsConnectedData.map((i) => {
                return i.reduce((n, { callCounts }) => n + callCounts, 0)
            })
        }
        return finalData
    }

    const returnMonthlyData = (winningOutcomeData, callAttemptsData, callConnecteData, uniqueCallConnectedData, mergedData) => {
        let x_axis_arr_month = Object.keys(groupByMonths(mergedData))
        let groupedWinningData = checkTheMonthData(Object.values(groupByMonths(winningOutcomeData)), x_axis_arr_month)
        let groupAttemptsData = checkTheMonthData(Object.values(groupByMonths(callAttemptsData)), x_axis_arr_month)
        let groupedCallsConnectedData = checkTheMonthData(Object.values(groupByMonths(callConnecteData)), x_axis_arr_month)
        let groupedUniqueLeadsConnectedData = checkTheMonthData(Object.values(groupByMonths(uniqueCallConnectedData)), x_axis_arr_month)
        
        let finalData = {
            x_axis: x_axis_arr_month,
            winning_outcomes: groupedWinningData.map((i) => {
                return i.reduce((n, { callCounts }) => n + callCounts, 0)
            }),
            call_attempts: groupAttemptsData.map((i) => {
                return i.reduce((n, { callCounts }) => n + callCounts, 0)
            }),
            calls_connected: groupedCallsConnectedData.map((i) => {
                return i.reduce((n, { callCounts }) => n + callCounts, 0)
            }),
            unique_leads_connected: groupedUniqueLeadsConnectedData.map((i) => {
                return i.reduce((n, { callCounts }) => n + callCounts, 0)
            })
        }
        return finalData
    }

    const renderChart = (e) => {
        setChartFilter(e.replace(/^./, e[0].toUpperCase()))
        let newDatasets = lineChartApiData.series.map(obj => {
            if (obj.name.replace(/\ /g, "_").toLowerCase() === "call_attempts") {
                return { ...obj, data: mainLineChartData[`${e}`].call_attempts }
            }
            else if (obj.name.replace(/\ /g, "_").toLowerCase() === "winning_outcomes") {
                return { ...obj, data: mainLineChartData[`${e}`].winning_outcomes }
            }
            else if (obj.name.replace(/\ /g, "_").toLowerCase() === "calls_connected") {
                return { ...obj, data: mainLineChartData[`${e}`].calls_connected }
            }
            else if (obj.name.replace(/\ /g, "_").toLowerCase() === "unique_leads_connected") {
                return { ...obj, data: mainLineChartData[`${e}`].unique_leads_connected }
            }
            else {
                return obj
            }
        })
        setLineChartApiData(
            {
                ...lineChartApiData,
                series: newDatasets,
                options: {
                    ...lineChartApiData.options,
                    xaxis: {
                        categories: mainLineChartData[`${e}`].x_axis
                    },
                },
            }
        )
    }

    const returnDonutData = (data) => {

        let cc_tags = []
        let cc_val = []

        let cc_sum = data.reduce((acc, currValue, index) => {
            const dispositionKey = Object.keys(currValue).find(key => /Disposition$/i.test(key));
            if (data[index].isWinning) {
                cc_tags.unshift(`${data[index][dispositionKey]} (${data[index].count})*`)
                cc_val.unshift(data[index].count)
            } else {
                cc_tags.push(`${data[index][dispositionKey]} (${data[index].count})`)
                cc_val.push(data[index].count)
            }
            return acc + currValue.count
        }, 0)

        if (cc_sum > 0) {
            cc_val = cc_val.map((val) => {
                return Math.round(((val * 100) / cc_sum), 0)
            })
        }


        let chartData = {
            ...donutChartData,
            options: {
                ...donutChartData.options,
                labels: cc_tags
            },
            series: cc_val
        }

        let dataCC = {
            sum: cc_sum,
            chartData: chartData
        }

        return dataCC

    }

    // for weekly data x-axis
    const formateDate = (date) => {
        let srtDt = moment(date).clone().startOf('week').format('MMM Do').replace(/th|rd|nd|st/g, "")
        let endDt = moment(date).clone().endOf('week').format('MMM Do').replace(/th|rd|nd|st/g, "")
        return srtDt.replace(" ", "") + "-" + endDt.replace(" ", "")
    }

    const directTrigger = () => {
        let id = props.match.params.id;
        let dataToSend = {
            teleproject: id
        }

        ApiActions.getTeleProject(id, 'brand').then(resp => {
            setData(resp)
            setBrandDetails(resp.brand)
            setActiveProject(resp)
            setDefaultStartDate(resp.startDate.split('T')[0])
            setDefaultEndDate(resp.endDate.split('T')[0])
            originalStartDate = resp.startDate.split('T')[0]
            originalEndDate = resp.endDate.split('T')[0]
            if(moment().toDate() > moment(resp.endDate.split('T')[0]).toDate()){
                setReportStartDate(moment(resp.endDate.split('T')[0]).toDate())
                setReportEndDate(moment(resp.endDate.split('T')[0]).toDate())
            }
            setScriptDocLink(resp.scriptDocuments[0].url)
            if(resp.endDate && moment().toDate() > moment(resp.endDate.split('T')[0]).toDate()){
                setChartEndDate(moment(resp.endDate.split('T')[0]).toDate())
                setChartStartDate(moment(resp.endDate.split('T')[0]).subtract(6,'days').toDate())
            }
        }).catch(err => {
            console.error(err)
        })

        ApiActions.getCallsStats(dataToSend)
        .then(resp => {
            setProjectStats({ ...resp })
            setLoading(false)
        }).catch(err => {
            console.error(err)
        })

        ApiActions.getMinutesCreditsStats({
            ...dataToSend,
            startDate : moment(new Date()).subtract(1,'month').format('DD/MM/YYYY'),
            endDate : moment(new Date()).format('DD/MM/YYYY')
        })
        .then(resp => {
            setProjectStats2({ ...resp })
            setLoading(false)
        })
        .catch(err => {
            console.error(err)
        })

      
    }

    useEffect(() => {
        let y = ''
        if(props.location.state == undefined){
                directTrigger()
        }else{
        let data = props.location.state.projectdata;
        let id = props.match.params.id;
        setData(data)
        let dataToSend = {
            teleproject: id
        }

        ApiActions.getCallsStats(dataToSend)
        .then(resp => {
            setProjectStats({ ...resp })
            setLoading(false)
        }).catch(err => {
            console.error(err)
        })

        ApiActions.getMinutesCreditsStats({
            ...dataToSend,
            startDate : moment(new Date()).subtract(1,'month').format('DD/MM/YYYY'),
            endDate : moment(new Date()).format('DD/MM/YYYY')
        })
        .then(resp => {
            setProjectStats2({ ...resp })
            setLoading(false)
        })
        .catch(err => {
            console.error(err)
        })

        ApiActions.getTeleProject(id, 'brand').then(resp => {
            // fetchBrandDetails(resp.brand).then((d) => {
            // setBrandDetails(...d)
            // }).catch(err => console.error(err))
            setBrandDetails(resp.brand)
            setActiveProject(resp)
            setDefaultStartDate(resp.startDate.split('T')[0])
            setDefaultEndDate(resp.endDate.split('T')[0])
            originalStartDate = resp.startDate.split('T')[0]
            originalEndDate = resp.endDate.split('T')[0]

            if(moment().toDate() > moment(resp.endDate.split('T')[0]).toDate()){
                setReportStartDate(moment(resp.endDate.split('T')[0]).toDate())
                setReportEndDate(moment(resp.endDate.split('T')[0]).toDate())
            }
            setScriptDocLink(resp.scriptDocuments[0].url)
            if(resp.endDate && moment().toDate() > moment(resp.endDate.split('T')[0]).toDate()){
                setChartEndDate(moment(resp.endDate.split('T')[0]).toDate())
                setChartStartDate(moment(resp.endDate.split('T')[0]).subtract(6,'days').toDate())
            }
        }).catch(err => {
            console.error(err)
        })
        }
    }, [])

    const getLineChartData = (from = null, to = null) => {
        setIsLineGrapTriggered(true)
        setShowLoad(false)
        setMainLineChartData({})
        setLineChartApiData({})
        let id = props.match.params.id;
        let startDate = moment(from ? from : chartStartDate).format('DD/MM/YYYY') 
        let endDate = moment(to ? to : chartEndDate).format('DD/MM/YYYY')
        if(from && to && moment(to).diff(moment(from), 'days') > 31){
            toast.error('The date range selected should be within 31 days')
            setShowLoad(true)
            return true
        }
        Promise.all([
            ApiActions.getCallInsightsLineChart({id, startDate, endDate, callParam:"winning"}), //chart - 1
            ApiActions.getCallInsightsLineChart({id, startDate, endDate, callParam:"callAttempt"}), //chart - 2
            ApiActions.getCallInsightsLineChart({id, startDate, endDate, callParam:"callsConnected"}), //chart - 3
            ApiActions.getCallInsightsLineChart({id, startDate, endDate, callParam:"uniqueCallsConnected"}), //chart - 4
        ]).then(resp => {
            let winningResult = resp[0].callInsightData.length > 0 ? 'present' : ''
            let callAttemptResult = resp[1].callInsightData.length > 0 ? 'present' : ''
            let callConnectedResult = resp[2].callInsightData.length > 0 ? 'present' : ''
            let uniqueCallConnectedResult = resp[3].callInsightData.length > 0 ? 'present' : ''

            if (!winningResult && !callAttemptResult && !callConnectedResult && !uniqueCallConnectedResult){
                setIsCallInsightsDataEmpty(true)
            }else{
                const isBothResultPresent = (resp[1].callInsightData.length > 0) && (resp[0].callInsightData.length > 0)
                && (resp[2].callInsightData.length > 0) && (resp[3].callInsightData.length > 0)
                
                if (isBothResultPresent) {
                    // (resp[0].callInsightData.length > 0 && resp[0]) && (resp[1].callInsightData.length > 0 && resp[1])
                    let winningOutcomesData = handleRawChartData(resp[0].callInsightData)
                    let callAttemptsData = handleRawChartData(resp[1].callInsightData)
                    let callConnecteData = handleRawChartData(resp[2].callInsightData)
                    let uniqueCallConnectedData = handleRawChartData(resp[3].callInsightData)
                    let mergedData = mergeTwoArray(winningOutcomesData, callAttemptsData, callConnecteData, uniqueCallConnectedData)
    
                    // let chartData = {
                    //     daily: returnDailyData(mergedData),
                    //     weekly: returnWeeklyData(winningOutcomesData, callAttemptsData, mergedData),
                    //     monthly: returnMonthlyData(winningOutcomesData, callAttemptsData, mergedData)
                    // }

                    let chartData = {
                        daily: returnDailyData(mergedData),
                        weekly: returnWeeklyData(winningOutcomesData, callAttemptsData, callConnecteData, uniqueCallConnectedData, mergedData),
                        monthly: returnMonthlyData(winningOutcomesData, callAttemptsData, callConnecteData, uniqueCallConnectedData, mergedData)
                    }
                    setMainLineChartData(chartData)
    
    
                    // setMainLineChartData((state) => {
                    //   console.log(state); // "React is awesome!"
                    //   return state;
                    // });
    
                    let new_data = {
                        ...lineChartData,
                        options: {
                            ...lineChartData.options,
                            xaxis: {
                                categories: returnDailyData(mergedData).x_axis
                            },
                        },
                        series: [
                            {
                                name: "Winning Outcomes",
                                data: chartData['daily'].winning_outcomes
                            },
                            {
                                name: "Call Attempts",
                                data: chartData['daily'].call_attempts
                            },
                            {
                                name: "Calls Connected",
                                data: chartData['daily'].calls_connected
                            },
                            {
                                name: "Unique Leads Connected",
                                data: chartData['daily'].unique_leads_connected
                            }
                        ]
                    }
                    // let new_data = formatTheLineGraphData(chartData,mergedData)
                    setLineChartApiData({ ...new_data })
                }else{
                    // let winningOutcomesData = handleRawChartData(resp[0].callInsightData)
                    let winningOutcomesData = resp[0].callInsightData.length > 0 ? handleRawChartData(resp[0].callInsightData) : []
                    let callAttemptsData = resp[1].callInsightData.length > 0 ? handleRawChartData(resp[1].callInsightData) : []
                    let callConnecteData = resp[2].callInsightData.length > 0 ? handleRawChartData(resp[2].callInsightData) : []
                    let uniqueCallConnectedData = resp[3].callInsightData.length > 0 ? handleRawChartData(resp[3].callInsightData) : []
                    let mergedData = mergeTwoArray(winningOutcomesData, callAttemptsData, callConnecteData, uniqueCallConnectedData)
    
                    // let chartData = {
                    //     daily: returnDailyData(mergedData),
                    //     weekly: returnWeeklyData(winningOutcomesData, callAttemptsData, mergedData),
                    //     monthly: returnMonthlyData(winningOutcomesData, callAttemptsData, mergedData)
                    // }

                    let chartData = {
                        daily: returnDailyData(mergedData),
                        weekly: returnWeeklyData(winningOutcomesData, callAttemptsData, callConnecteData, uniqueCallConnectedData, mergedData),
                        monthly: returnMonthlyData(winningOutcomesData, callAttemptsData, callConnecteData, uniqueCallConnectedData, mergedData)
                    }
    
                    let new_data = {
                        ...lineChartData,
                        options: {
                            ...lineChartData.options,
                            xaxis: {
                                categories: returnDailyData(mergedData).x_axis
                            },
                        },
                        series: [
                            {
                                name: "Winning Outcomes",
                                data: chartData['daily'].winning_outcomes
                            },
                            {
                                name: "Call Attempts",
                                data: chartData['daily'].call_attempts
                            },
                            {
                                name: "Calls Connected",
                                data: chartData['daily'].calls_connected
                            },
                            {
                                name: "Unique Leads Connected",
                                data: chartData['daily'].unique_leads_connected
                            }
                        ]
                    }
                    setMainLineChartData(chartData)
                    setLineChartApiData({ ...new_data })
                }
            }
        }).catch(err => console.error(err)).finally(() => {
            setIsLineGrapTriggered(false)
        })
    }

    const fetchCallStats = (startDate,endDate) => {

        const dataToSend = {
            teleproject: props.match.params.id,
            startDate: startDate,
            endDate: endDate
        }
        ApiActions.getMinutesCreditsStats(dataToSend)
        .then(resp => {
            setProjectStats2({ ...resp })
            setLoading(false)
            setHideStats(false)
        })
        .catch(err => {
            console.error(err)
        })
    }

    const fetchLeadsDistribution = async () => {

        try {
            let id = props.match.params.id;

            const concludedLeadsDistribution = await ApiActions.getConcludedLeadsDistribution(id)
            let donutCCData = returnDonutData(concludedLeadsDistribution.connected)
            setContCallDonutData(donutCCData.chartData)
            setConnectedCallsTotal(donutCCData.sum)

            let donutFCData = returnDonutData(concludedLeadsDistribution.notConnected)
            setFailCallDonutData(donutFCData.chartData)
            setNotConnectedCallsTotal(donutFCData.sum)

            const inprogressConnectedLeadsDistribution = await ApiActions.getInprogressConnectedLeadsDistribution(id)
            let donutICData = returnDonutData(inprogressConnectedLeadsDistribution)
            setInProgressConnDonutData(donutICData.chartData)
            setInProgressConnCallsTotal(donutICData.sum)

            setIsLeadStatsTriggered(true)
        }
        catch (err) {
            console.error(err)
        }

    }

    useEffect(() => {
        if(auditInfo.type == 'iqc'){
            getIQCStats()
        }
        if(auditInfo.type == 'performanceAudit'){
            getQmfStats()
        }
    }, [auditInfo.type, statsStartDate && statsEndDate])

    const getQmfStats = async () => {
        let id = props.match.params.id;
        let result = await ApiActions.getPerformaceAuditStats({
            "teleprojectId": id,
            "startDate": moment(statsStartDate).format('YYYY-MM-DD'),
            "endDate": moment(statsEndDate).format('YYYY-MM-DD')
        })
        
        if(result)
        setAuditInfo({
            type: 'performanceAudit',
            loading: false,
            data: {...result, 'Parameter wise defects %' : <span className='view_parameter_btn' onClick={() => setShowParameter(true)}>View</span>}
        })
    }

    const getIQCStats = async () => {
        let id = props.match.params.id;
        let result = await ApiActions.getIQCAuditStats({
            "teleprojectId": id,
            "startDate": moment(statsStartDate).format('YYYY-MM-DD'),
            "endDate": moment(statsEndDate).format('YYYY-MM-DD')
        })
        if(result)
            setAuditInfo({
                type: 'iqc',
                loading: false,
                data: {...result, 'Parameter wise defects %' : <span className='view_parameter_btn' onClick={() => setShowParameter(true)}>View</span>}
            })
    }

    const customReadableName = (key) => {
        switch (key) {
            case 'avgScore':
                return 'Avg Score'
            case 'callersAudited':
                return 'No. of callers audited'
            case 'passRate':
                return 'Pass rate'
            case 'fatalAuditsCount':
                return 'No. of fatals audited'
            case 'callsAudited':
                return 'No. of calls audited'
            default:
                return key
        }
    }
    
    const customLabelDescription = (key) => {
        switch (key) {
            case 'avgScore':
                return `Average QA score of total audits conducted`
            case 'passRate':
                return `Percentage of ${auditInfo.type == 'iqc' ? `callers who have average` : 
                    `calls with`
                } QA score of 85% and above`
            case 'fatalAuditsCount':
                return `Total calls with fatal remarks`
            default:
                return 'No description'
        }
    }

    const mainScreenRender = () => {
        const { completedLeads, inprogressLeads, leadDatabase } = projectStats
        const { attemptedLeads, connectedLeads, minutesSpoken, creditsConsumed } = projectStats2
        const winningOutcomes = activeProject && activeProject.outcomes ? activeProject.outcomes.filter(outcome => outcome.isWinning) : []
        let callConnectivity = Math.round((connectedLeads / attemptedLeads) * 100)
        callConnectivity = isNaN(callConnectivity) ? 0 : callConnectivity

        const goBack = () => {
            // props.history.push("/projects")
            props.history.push({
                pathname: '/projects',
                state: {
                    activeTab: props && props.location && props.location.state && props.location.state.activeTab,
                    search: props && props.location && props.location.state && props.location.state.search
                }
            })
        }
        // if (!loading)
        return (
            <Fragment>
                <div className="wrapper-tring-dashboard">
                    <div className="details_page__container">
                        <div className="details_page__wrapper">

                            {/* project title and dropdown section */}
                            <div className="heading_bar">
                                {data ? <ProjectTitle title={data.title} goBack={goBack} brandName={brandDetails && brandDetails.name}/> : null }
                            </div>
                            {/* end of project title and dropdown */}

                            {/* project stats banner */}
                            <div className="stats__header">
                                <div className="stats__box flex h-60">
                                    <div className="stats__heading">
                                        <img src={require("../../assets/images/callsdb.svg")} />
                                        <h4> Database: </h4>
                                    </div>
                                    <CustomTooltip 
                                        placement={'bottom'} 
                                        description={'Refers to leads that have been successfully uploaded to the project'} 
                                        component={
                                            <div className="stats__numbers">
                                                <h4>{leadDatabase}</h4>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="stats__box flex h-60">
                                    <div className="stats__heading">
                                        <img src={require("../../assets/images/completed.svg")} />
                                        <h4> Leads Concluded: </h4>
                                    </div>
                                    <CustomTooltip 
                                        placement={'bottom'} 
                                        description={'Refers to leads where a final disposition has been received'} 
                                        component={
                                            <div className="stats__numbers">
                                                <h4>{completedLeads}</h4>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="stats__box flex h-60">
                                    <div className="stats__heading">
                                        <img src={require("../../assets/images/call_queue.svg")} />
                                        <h4> Leads Pending: </h4>
                                    </div>
                                    <CustomTooltip 
                                        placement={'bottom'} 
                                        description={'Refers to leads that are under process and are undergoing calling'} 
                                        component={
                                            <div className="stats__numbers">
                                                <h4>{inprogressLeads}</h4>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            {/* end of project stats banner */}

                            {/* call insights graph and call stats section */}
                            <div className="chart__cont_1">
                                <div className="line__calls_insights">
                                    <div className="chart__header">
                                        <div>
                                            <img src={require("../../assets/images/call_icon.svg")} />
                                            <h4> Call Insights </h4>
                                        </div>
                                        <div>
                                            <div className="chart__dropdown">
                                                <Datepicker
                                                    placeholderText="Click to select a date"
                                                    // selectedDate={chartStartDate}
                                                    isDateRange = {true}
                                                    minDate={moment(defaultStartDate).toDate()}
                                                    maxDate={moment().toDate() <= moment(defaultEndDate).toDate() ?
                                                    moment().toDate() : moment(defaultEndDate).toDate()}
                                                    from={chartStartDate}
                                                    to={chartEndDate}
                                                    onChange={(daterange) => {
                                                        const [from, to] = daterange
                                                        if(from && to){ getLineChartData(moment(from).toDate(), moment(to).toDate()) }
                                                    }}
                                                />
                                                {lineChartApiData.options ? <img className='refresh-icon' src={require("../../assets/images/Icons_refresh.svg")} onClick={() => getLineChartData()} /> : ''}
                                                {lineChartApiData.options && chartStartDate ? <DropdownButton
                                                    bsSize="small"
                                                    title={chartFilter}
                                                    id="dropdown-size-small"
                                                >
                                                    <MenuItem eventKey="daily" onSelect={(e) => renderChart(e)}>Daily</MenuItem>
                                                    <MenuItem eventKey="weekly" onSelect={(e) => renderChart(e)}>Weekly</MenuItem>
                                                    <MenuItem eventKey="monthly" onSelect={(e) => renderChart(e)}>Monthly</MenuItem>
                                                </DropdownButton> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"chart__div"}>
                                        {lineChartApiData.options ? <Chart
                                            options={lineChartApiData.options}
                                            series={lineChartApiData.series}
                                            type="line"
                                            height="300"
                                        /> :
                                            <div className='load-btn-wrapper'>
                                                {showLoad ? <p className='load-graph' onClick={() => getLineChartData()}>Load graph</p> : 
                                                isLineGrapTriggered ? <ThreeDotLoader/> : isCallInsightsDataEmpty && 
                                                <p>No Data Available</p>}
                                            </div>

                                        }
                                    </div>
                                </div>
                                
                                <div className="stats_right__wrapper">
                                    <div className="chart__header">
                                        <div>
                                        <img src={require("../../assets/images/call_icon.svg")} />
                                        <h4> Call Stats </h4>
                                        </div>
                                        <div>
                                        <div className="chart__dropdown">
                                            <Datepicker
                                                placeholderText="select a date"
                                                // selectedDate={chartStartDate}
                                                isDateRange = {true}
                                                minDate={moment(defaultStartDate).toDate()}
                                                maxDate={ maxDateRange }
                                                from = {moment(new Date()).subtract(1,'month').toDate()}
                                                to = {moment(new Date()).toDate()}
                                                onChange={(daterange) => {
                                                    const [from, to] = daterange
                                                    if(from) setMaxDateRange(Math.min(moment(defaultEndDate),moment(currentDay),moment(from).add(1,'month')))
                                                    if (from && to) {
                                                        fetchCallStats(moment(from).format('DD/MM/YYYY'),moment(to).format('DD/MM/YYYY'))
                                                    }
                                                }}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="stats__box flex h-60">
                                            <div className="stats__heading">
                                                <img src={require("../../assets/images/callsdb.svg")} />
                                                <h4> Connectivity: </h4>
                                            </div>
                                            <CustomTooltip 
                                                placement={'bottom'} 
                                                description={'The % of leads connected out of those attempted'} 
                                                component={
                                                    <div className="stats__numbers">
                                                        <h4>{callConnectivity}<span style={{ fontSize: "20px" }}>%</span></h4>
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className="stats__box flex h-60">
                                            <div className="stats__heading">
                                                <img src={require("../../assets/images/credits.svg")} />
                                                <h4> Total Minutes: </h4>
                                            </div>
                                            <CustomTooltip 
                                                placement={'bottom'} 
                                                description={'The total number of minutes consumed'} 
                                                component={
                                                    <div className="stats__numbers">
                                                        <h4>{creditsConsumed}</h4>
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className="stats__box">
                                            <div className="winning_outcomes_wrapper">
                                                <div className="stats__heading mt-8 mb-8">
                                                    <img src={require("../../assets/images/Icons_winning_outcome.svg")} />
                                                    <h4> Winning Outcomes: </h4>
                                                </div>
                                                <CustomTooltip 
                                                    placement={'bottom'} 
                                                    description={'Outcomes that are considered to be winning outcomes'} 
                                                    component={
                                                        <div className="stats__numbers">
                                                            {
                                                                winningOutcomes && winningOutcomes.length > 0 ? (
                                                                    winningOutcomes.map((outcome, i) => <p key={i}>{outcome.title}</p>)
                                                                ) : 'NA'
                                                            }
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end of call insights graph and call stats section */}

                            {/* Donut chart -- Connected Leads graph */}
                            <div className="chart__cont_2">
                                <div className="doughnut__connected_calls">
                                    <div className="doughnut__header green__back green__heading">
                                        <h4>Leads stats</h4>
                                        <div className='action-row'>
                                            <div>
                                            <select name="lead-type" id="label-select" className='lead_label' value={leadType.type}
                                            onChange={(e) => setLeadType({type: e.target.value})}>
                                            {/* <option value="" hidden={inputFields.poolType}>Select pool type</option> */}
                                            <option value={CONNECTED}>Concluded (Connected)</option>
                                            <option value={NOT_CONNECTED}>Concluded (Not Connected)</option>
                                            <option value={INPROGRESS_CONNECTED}>Inprogress (Connected) </option>
                                            </select>
                                            </div>
                                            
                                            <div>
                                                <img className='refresh-icon' src={require("../../assets/images/Icons_refresh.svg")} onClick={fetchLeadsDistribution} />
                                            </div>
                                        </div>
                                    
                                        {/* <h4> Connected Leads ({connectedCallsTotal ? connectedCallsTotal : 'NA'})</h4> */}
                                    </div>
                                    {(() => {
                                            switch(leadType.type) {
                                                case CONNECTED:
                                                    return (
                                                        <div className={"doughnut__div"}>
                                                            {!isEmptyData(contCallDonutData) ? (
                                                                <Chart
                                                                    options={contCallDonutData.options}
                                                                    series={contCallDonutData.series}
                                                                    type="donut"
                                                                    height='200'
                                                                />
                                                            ) : (
                                                                <div className='load-btn-wrapper'>
                                                                    {!isLeadStatsTriggered ? (
                                                                        <p className='load-graph' onClick={fetchLeadsDistribution}>Load graph</p>
                                                                    ) : (
                                                                        <p>No data available</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {!isEmptyData(contCallDonutData) && (
                                                                <p className="hint_ptag">*(winning outcome)</p>
                                                            )}
                                                        </div>
                                                    );
                                                    
                                                case NOT_CONNECTED:
                                                    return (
                                                        <div className={"doughnut__div"}>
                                                            {!isEmptyData(failCallDonutData) ? (
                                                                <Chart
                                                                    options={failCallDonutData.options} 
                                                                    series={failCallDonutData.series}
                                                                    type="donut"
                                                                    height='200'
                                                                />
                                                            ) : (
                                                                <div className='load-btn-wrapper'>
                                                                    {!isLeadStatsTriggered ? (
                                                                        <p className='load-graph' onClick={fetchLeadsDistribution}>Load graph</p>
                                                                    ) : (
                                                                        <p>No data available</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    );

                                                case INPROGRESS_CONNECTED:
                                                    return (
                                                        <div className={"doughnut__div"}>
                                                            {!isEmptyData(inProgressConnDonutData) ? (
                                                                <Chart
                                                                    options={inProgressConnDonutData.options}
                                                                    series={inProgressConnDonutData.series}
                                                                    type="donut"
                                                                    height='200'
                                                                />
                                                            ) : (
                                                                <div className='load-btn-wrapper'>
                                                                    {!isLeadStatsTriggered ? (
                                                                        <p className='load-graph' onClick={fetchLeadsDistribution}>Load graph</p>
                                                                    ) : (
                                                                        <p>No data available</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    );

                                                default:
                                                    return null;
                                            }
                                        })
                                        ()
                                    }
                                </div>
                                <div className="doughnut__failed_calls">
                                    <div className="doughnut__header red__back red__heading">
                                        <h4>QA Stats</h4>
                                        <div className='action-row'>
                                            <div>
                                            <select name="audit-type" id="audit-select" className='lead_label' value={auditInfo.type}
                                            onChange={(e) => {
                                                setAuditInfo({type: e.target.value, loading: true, data: ''})
                                            }}>
                                            <option value={'iqc'}>IQC</option>
                                            <option value={'performanceAudit'}>Performance Audit</option>
                                            </select>
                                            </div>
                                            

                                            {activeProject && <Datepicker
                                            placeholderText="Click to select a date"
                                            isDateRange = {true}
                                            minDate={moment(originalStartDate).toDate()}
                                            maxDate={moment(originalEndDate).toDate()}
                                            from={statsStartDate}
                                            to={statsEndDate}
                                            onChange={(daterange) => {
                                                const [from, to] = daterange
                                                if(from && to){
                                                    setStatsStartDate(moment(from).toDate())
                                                    setStatsEndDate(moment(to).toDate())

                                                    setAuditInfo({
                                                        ...auditInfo,
                                                        loading: true,
                                                        data: ''
                                                    })

                                                }
                                            }}
                                            />}

                                            {/* <div>
                                                <img className='refresh-icon' src={require("../../assets/images/Icons_refresh.svg")} onClick={fetchLeadsDistribution} />
                                            </div> */}
                                        </div>
                                        {/* <h4> Not Connected Leads ({notConnectedCallsTotal ? notConnectedCallsTotal : 'NA'})</h4> */}

                                    </div>
                                    <div className={"doughnut__div2"}>
                                        <div className='qa_stats_wapper'>
                                            {
                                                auditInfo.loading ? <ThreeDotLoader/> :
                                                auditInfo.data ? 
                                                    <table className='qa_list'>
                                                        {
                                                            auditInfo.data && Object.entries(auditInfo.data).map(([key, value]) => {
                                                                return <tr className='qa_row'>
                                                                <td className='qa_column'>
                                                                {customReadableName(key)}
                                                                &nbsp;
                                                                {['avgScore', 'passRate', 'fatalAuditsCount'].includes(key) ? <CustomTooltip placement={'right'} component={<img src={QuestionMarkCircle}  className='helper_icon'/>}
                                                                description={customLabelDescription(key)}/> : null}
                                                                </td>
                                                                <td className='qa_column'>{value}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </table>
                                                :
                                                <div>No data available</div>
                                            }
                                        
                                        </div>
                                        {/* {
                                            !isEmptyData(failCallDonutData) ? <Chart
                                                options={failCallDonutData.options}
                                                series={failCallDonutData.series}
                                                type="donut"
                                                height='200'
                                            /> :
                                                <div className='load-btn-wrapper'>
                                                    {!isNotConnectTriggered ? <p className='load-graph' onClick={fetchLeadsDistribution}>Load graph</p> : <p>No data available</p>}
                                                </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                            {/* end of Connected Leads graph */}


                            {/* download report  & preview script section  */}
                            <div className="dashboard_bottom_cta">
                                <FrappButton
                                    className="download__btn"
                                    handler={() => reportModal.toggle(true)}
                                >Download Report</FrappButton>
                                <FrappButton
                                    className="preview_script__btn"
                                    // disabled={previewScript.length > 0 ? false : true}
                                    handler={() => {
                                        // fetchScript()
                                        openPreview()
                                        // previewScriptModal.toggle(true)
                                    }}
                                >Script Preview</FrappButton>
                            </div>
                            {/* end of download report & preview script section  */}
                        </div>
                    </div>
                </div>
                {renderDownloadReportsModal()}
                {renderPreviewScriptModal()}
                {renderParameterWiseDefects()}
            <ToastContainer draggable={true} />
            </Fragment>
        )
        // else
        //   return <Loader />
    }

    const renderDownloadReportsModal = () => {
        let app;
        if (activeProject && activeProject.outcomes) {
            app = activeProject.outcomes.map((item, id) => {
                return ({
                    "id": id,
                    "outcome": item.title
                })
            })
        }

        const triggerStartDatePicker = () => {
            setStartDateFocussed(true)
        };
        const triggerEndDatePicker = () => {
            setEndDateFocussed(true)
        };
        var startDate = defaultStartDate
            ? moment(new Date(defaultStartDate))
            : null;
        var endDate = defaultEndDate
            ? moment(new Date(defaultEndDate))
            : null;

        const removeEmailFromArray = (e, idx) => {
            let newArray = emailArray.filter((email, id) => id !== idx)
            setEmailArray(newArray)
        }
        const addEmailtoArray = (e) => {
            e.preventDefault();
            if (eachEmail.length > 0)
                emailArray.push(eachEmail);
            setEmailArray(emailArray)
            setEachEmail("")
        }
        const setEmail = (e) => {
            setEachEmail(e.target.value)
            setReportSent(false)
        }
        const sendReport = () => {
            let dataToSend;
            if (emailArray.length === 0) {
                dataToSend = {
                    "teleproject": activeProject.id,
                    "startDate": moment(reportStartDate).format('YYYY-MM-DD'),
                    "endDate": moment(reportEndDate).format('YYYY-MM-DD'),
                    "outcomes": outcomesArrayToSend
                }
            } else {
                dataToSend = {
                    "teleproject": activeProject.id,
                    "startDate": moment(reportStartDate).format('YYYY-MM-DD'),
                    "endDate": moment(reportEndDate).format('YYYY-MM-DD'),
                    "outcomes": outcomesArrayToSend,
                    "emails": JSON.stringify(emailArray)
                }
            }
            if(reportType == HISTORIC_PROJECT_REPORT){
                dataToSend.isLite = true
            }
            submitBtnReport.startLoading();
            ApiActions.getProjectReports(dataToSend).then(resp => {
                submitBtnReport.stopLoading();
                setReportSent(true)
            }).catch(err => {
                setReportSent(false)
                if(err && err.response && err.response.data && err.response.data.code === 'MUL_REP_REQ_ERR'){
                    setReportError({
                        status: true,
                        msg: err.response.data.msg
                    })
                }
            })
        }
        const setReportDate = (date, start) => {
            let tempStartDate, tempEndDate
            if (start) {
                tempStartDate = date.format('YYYY-MM-DD');
                setDefaultStartDate(tempStartDate)
                startDate = tempStartDate
            }
            if (!start) {
                tempEndDate = date.format('YYYY-MM-DD');
                setDefaultEndDate(tempEndDate)
                endDate = tempEndDate
            }

        }

        const closeReportModal = () => {
            setReportSent(false)
            setEmailArray([])
            setOutcomesArrayToSend([])
            reportModal.toggle(false)
            setDefaultEndDate(originalEndDate)
            setDefaultStartDate(originalStartDate)
            setReportError({
                status: false,
                msg: ''
            })
            setReportEndDate(moment().toDate())
            setReportStartDate(moment().toDate())
            setReportType(REGULAR_REPORT)
        }

        return <FrappModal className="cropper-modal" id="report-modal"
            //  show={this.state.reportModal}
            ref={c => (reportModal = c)}
            closeButtonClicked={() => closeReportModal()}
        >
            <div className="report-modal">
                <div className='report-type'>
                    <span className='heading'>Report type: </span>
                <DropdownButton
                bsSize="small"
                title={reportType == HISTORIC_PROJECT_REPORT ? 'Historic report' : 'Regular report'}
                id="dropdown-size-small"
                >
                <MenuItem eventKey={HISTORIC_PROJECT_REPORT} onSelect={(e) => {
                    setReportEndDate(moment().toDate())
                    setReportStartDate(moment().toDate())
                    setReportType(e)
                }}>Historic report</MenuItem>
                <MenuItem eventKey={REGULAR_REPORT} onSelect={(e) => {
                      setReportEndDate(moment().toDate())
                      setReportStartDate(moment().toDate())
                    setReportType(e)
                }}>Regular report</MenuItem>
                </DropdownButton>
                </div>
                <div className="pill-wrapper">
                    {emailArray.map((emails, index) => {
                        return <div className="pill">
                            {emails}
                            <div className="del-icon" onClick={(e) => removeEmailFromArray(e, index)}>
                                <img src={require("../../assets/images/delete-icon.png")} />
                            </div>
                        </div>;
                    })}
                </div>
                <div className="email-field">
                    <form style={{ display: "flex", alignItems: "baseline" }} onSubmit={(e) => addEmailtoArray(e)}>
                        <div>
                            <FormGroup>
                                <FormControl placeholder="Enter" name="assign" type="email" value={eachEmail} onChange={(e) => setEmail(e)} />
                            </FormGroup>
                        </div>
                        <div className="plus-icon">
                            <button type="submit">
                                <img src={require("../../assets/images/plusicon.PNG")} />
                            </button>
                        </div>
                    </form>
                </div>
                <div>
                    <div className="date-report">
                        <div name="startDate" onClick={() => triggerStartDatePicker()} id="startdate">
                            {
                                reportType == HISTORIC_PROJECT_REPORT ? 
                                <Datepicker
                                dateFormat="dd/MM/yyyy"
                                selectedDate={reportStartDate}
                                placeholderText='Select start date'
                                minDate={moment(defaultStartDate).toDate()}
                                maxDate={moment().toDate() > moment(defaultEndDate).toDate() ? 
                                moment(defaultEndDate).toDate() : moment().toDate()}
                                onChange={date => {
                                setReportStartDate(date)
                                setReportEndDate(moment(date).add(90,'days').toDate() > moment(defaultEndDate).toDate() ?
                                moment(defaultEndDate).toDate() : moment(date).add(90,'days').toDate())
                                }}
                                /> 
                                :
                                <Datepicker
                                dateFormat="dd/MM/yyyy"
                                selectedDate={reportStartDate}
                                placeholderText='Select start date'
                                minDate={moment(defaultStartDate).toDate()}
                                maxDate={moment().toDate() > moment(defaultEndDate).toDate() ? 
                                moment(defaultEndDate).toDate() : moment().toDate()}
                                onChange={date => {
                                setReportStartDate(date)
                                setReportEndDate(moment(date).add(30,'days').toDate() > moment(defaultEndDate).toDate() ?
                                moment(defaultEndDate).toDate() : moment(date).add(30,'days').toDate())
                                }}
                                />
                            }
                            <div className="info">Start Date</div>
                        </div>
                        <div className="endDate" onClick={() => triggerEndDatePicker()} id="enddate">

                            {
                                reportType == HISTORIC_PROJECT_REPORT ?
                                <Datepicker
                                dateFormat="dd/MM/yyyy"
                                selectedDate={reportEndDate}
                                placeholderText='Select start date'
                                minDate={moment(reportStartDate).toDate() > moment(defaultStartDate).toDate() ? moment(reportStartDate).toDate() : moment(defaultStartDate).toDate()}
                                maxDate={moment().toDate() > moment(defaultEndDate).toDate() ? moment(defaultEndDate).toDate() : moment().toDate()}
                                onChange={date => {
                                setReportEndDate(date)
                                if(!reportStartDate) setReportStartDate(moment(date).subtract(30,'days').toDate())
                                }}
                                />
                                :
                                <Datepicker
                                dateFormat="dd/MM/yyyy"
                                selectedDate={reportEndDate}
                                placeholderText='Select start date'
                                minDate={moment(reportStartDate).toDate() > moment(defaultStartDate).toDate() ? moment(reportStartDate).toDate() : moment(defaultStartDate).toDate()}
                                maxDate={moment(reportStartDate).add(30,'days').toDate() > moment(defaultEndDate).toDate() ? moment(defaultEndDate).toDate() : moment(reportStartDate).add(30,'days').toDate()}
                                onChange={date => {
                                setReportEndDate(date)
                                if(!reportStartDate) setReportStartDate(moment(date).subtract(30,'days').toDate())
                                }}
                                />
                            }
                            <div className="info">End Date</div>
                        </div>
                    </div>
                    <p>
                        Remember to press the green plus-icon before you click on 'Send Report'
                        <br />
                        If you enter no email the report will be sent to
                        the default email list.
                    </p>
                </div>
                <div className="multiselect-dropdown">
                    <Multiselect
                        options={activeProject.outcomes}
                        displayValue="title"
                        closeIcon="cancel"
                        onSelect={(e) => {
                            setOutcomesArrayToSend(e.map((item) => {
                                return item.title
                            }))
                        }}
                    />
                </div>
                <div style={{ width: "100%" }}>
                    {reportSent ? <div className="success-banner">
                        Preparing your report - you will receive it on email shortly.Here is some <a href="http://www.allolo.ru/" target="_blank" rel="noopener noreferrer">
                            Relaxing Music
                        </a> to listen to till then.
                        <FrappButton className="submit" id="new-btn-report" type="submit" handler={() =>
                            setReportSent(false)}
                            ref={c => (submitBtnReport = c)}
                        >
                            Send Again
                        </FrappButton>
                    </div> : reportError.status ? <div className='error-message'>{reportError.msg}</div> : <FrappButton className="submit" id="new-btn-report" type="submit" handler={() =>
                        sendReport()}
                        ref={c => (submitBtnReport = c)}
                    >
                        Send Report
                    </FrappButton>}
                </div>
            </div>
        </FrappModal>;
    }

    const openPreview = () => {
        previewScriptModal.toggle(true)
        fetchScript()

    }

    const fetchScript = () => {
        let id = props.match.params.id;
        ApiActions.getScript(id)
            .then(resp => {
                if (resp) {
                    let script = resp[0] ? resp[0].script : ''
                    setPreviewScript(resp)
                    setIsPreview(true)
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const renderPreviewScriptModal = () => {
        return (
            <FrappModal
                // onCloseButton={false}
                className="faq-popup"
                // className="report-modal"
                closeModal={() => {
                    previewScriptModal.toggle(false)
                }}
                ref={c => (previewScriptModal = c)}
                header={'IS Preview'}
            >
                {/* <div className="heading">IS Preview</div> */}
                {/* <div className="faq-popup">
      <PreviewInteractiveScript script={previewScript} projectTitle={data.title.toString()} />
      </div> */}
                <div className="fm-report-download" style={{ width: "473px", overflowY: 'hidden' }}>
                    {isPreview ? <PreviewInteractiveScript allScripts={previewScript} projectTitle={data.title.toString()} faqs={activeProject.faqs && activeProject.faqs.length > 0 ? activeProject.faqs : []} 
                    teleprojectdata={data}/> : 'Loading.....'}
                    <div className='btn-footer'>
                        <div className='script-link-wrapper'>
                            <span>Script</span>
                            <a href='#' onClick={() => {
                                let newWnd = window.open(`${scriptDocLink}`, '_blank')
                                newWnd.opener = null
                            }}>Script Link</a>
                            {/* <div className='text-center my-2'><button className="faq-btn" onClick={close}>Close</button></div>*/}
                        </div>
                    </div>
                </div>
            </FrappModal>
        )
    }

    const renderParameterWiseDefects = () => {
        let id = props && props.match && props.match.params && props.match.params.id;
        const payload = {
            "teleprojectId": id,
            "auditType": auditInfo.type,
            "startDate": moment(statsStartDate).format('YYYY-MM-DD'),
            "endDate": moment(statsEndDate).format('YYYY-MM-DD')
        }

        const auditTypeNaming = () => {
            if(auditInfo.type == 'iqc'){
                return `IQC`
            }
            if(auditInfo.type == 'performanceAudit'){
                return `Performance Audit`
            }
        }

        return (
            <Popup
                show={showParameter}
                closePopup={() => {
                    setShowParameter(false)
                }}
                size='md'
                heading={`Parameter wise defects % for ${auditTypeNaming()}`}
            >
                 <ParameterWiseDefects payload={payload}/>
            </Popup>
        )
    }

    return (
        !loading ? mainScreenRender() : <Loader />
    )
}

export default NewTringStatsScreen
