import React, {useState, useEffect} from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/caller/roster.module.scss'
import {getMatchedCallers} from './service'
import useApi from '../../../hooks/useApi'
import Table from '../../../uicomponents/table/Table'
import Popup from '../../../uicomponents/popup'
import JobsHistory from './jobHistory'
import CurrentJobs from './currentJob'
import {TrackHistory} from '../../../assets/images'
import { getCallerJobHistory } from './service';
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'

const MatchTable = () => {
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [load, setLoad] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState('')

    const [showCallerHistory, setShowCallerHistory] = useState(false)
    const [showActiveJobs, setShowActiveJobs] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')
    const [showRemarkPopup, setShowRemarkPopup] = useState(false);
    const [showCallerRemark, setShowCallerRemark] = useState([]);

    const getMatchedCallerApi = useApi(getMatchedCallers)
    const getCallerJobHistoryApi = useApi(getCallerJobHistory);

    useEffect(()=>{
        const skip = (currentPage - 1) * limit
        getMatchedCallerApi.request(skip,limit,sort,search)
    },[currentPage, sort,search])

    const columns = [
        {
            Header: "Name",
            accessor: (row) => `${row.firstname || 'NA'} ${row.lastname || 'NA'}`,
            disableSortBy: true
        },
        {
            Header: "Mobile",
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Email',
            accessor: "email",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: "Current jobs",
            accessor: "activeJobCount",
            Cell: ({cell}) => (
                <div className={styles.blue_link}
                onClick={() => {
                    setSelectedRowData(cell.row.original)
                    setShowActiveJobs(true)
                }}>
                Jobs ({cell.row.original.activeJobCount ?
                cell.row.original.activeJobCount : 0})
                </div>
            ),
        },
        {
            Header: "Job History",
            Cell: (cell) => (
                <img src={TrackHistory} alt='Job History' onClick={() => {
                    setShowCallerHistory(true)
                    setSelectedRowData(cell.row.original)
                }}/>
            ),
            disableSortBy: true
        },
        {
            Header: "Remark",
            accessor: 'remark',
            Cell: ({ row }) => {
                return (
                    <FontAwesomeIcon
                        icon={faMessage}
                        className={styles.remark_icon}
                        onClick={() => {
                            setSelectedRowData(row.original);
                            getCallerJobHistoryApi.request({ user: row.original.id, status: "quit" });
                            setShowRemarkPopup(true);
                        }}
                    />
                );
            },
            disableSortBy: true,
        },
        
        
        //to be unlocked in 2nd phase
        // {
        //     Header: '',
        //     accessor: "action",
        //     Cell: (cell) => (
        //     <div className={styles.red_link} onClick={() => console.log(cell.row.original)}>
        //     Uncertify
        //     </div>
        //     ),
        //     disableSortBy: true
        // }
    ]

    const sortChange = (sortArray) => {
        if(sortArray !== '')
        setSort(sortArray)
        if(sortArray === '' && sort.length == 1)
        setSort([])
    }

    const filterChange = (d) => {
        setSearch(d)
        setCurrentPage(1)
    }

    const {data, total} = getMatchedCallerApi.data || {}
    const {loading} = getMatchedCallerApi


    const renderJobHistory = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showCallerHistory}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallerHistory(false)
            }}
            size='lg'
            heading={`Job history of ${firstname} ${lastname}`}
            popupContent = {<JobsHistory rowData={selectedRowData} />}
            />
        )
    }
    const renderRemarkPopup = () => {
        if (!getCallerJobHistoryApi.data) {
            return null;
        }
    
        let quitRemarks = [];
        getCallerJobHistoryApi.data.forEach(project => {
            if (project.statusChangeLog && project.statusChangeLog.length > 0) {
                const latestLog = project.statusChangeLog.reduce((latest, log) => {
                    const logDate = new Date(log.updatedAt);
                    const latestDate = latest ? new Date(latest.updatedAt) : null;
                    return (!latestDate || logDate > latestDate) ? log : latest;
                }, null);
    
                if (latestLog && latestLog.reason.includes("Quit") && latestLog.remark) {
                    quitRemarks.push({
                        teleproject: project.teleproject, 
                        remark: latestLog.remark, 
                        date: latestLog.updatedAt, 
                        reportedBy: latestLog.actionBy 
                    });
                }
            }
        });
        // sort the remarks by latest first 
        quitRemarks.sort((a, b) => new Date(b.date) - new Date(a.date));
    
        const remarkColumns = [
            { Header: 'Project ID', accessor: 'teleproject', disableSortBy: true },
            { Header: 'Remark', accessor: 'remark', disableSortBy: true },
            { Header: 'Date of Remark', accessor: 'date',
                Cell: ({cell}) => {
                    const {date: value} = cell.row.original
                    return <div>{value ? 
                        <span>{moment(value).format("DD/MM/YYYY")} | {moment(value).format('hh:mm A')}</span>
                        : 'N/A'
                    }</div>
                },
            //   Cell: ({ value }) => (value ? new Date(value).toLocaleString() : 'N/A')
               disableSortBy: true 
            },
            { Header: 'Reported By', accessor: 'reportedBy', disableSortBy: true }
        ];
    
        return (
            <Popup
                show={showRemarkPopup}
                closePopup={() => {
                    setSelectedRowData("");
                    setShowRemarkPopup(false);
                    getCallerJobHistoryApi.reset();
                }}
                size="md" 
                heading={<div>
                <div>Remark</div>
                <div className={styles.render_remark_divider}></div>
                </div>}
                popupContent={
                    getCallerJobHistoryApi.loading ? (
                        <div style={{ textAlign: "center" }}>
                            <ThreeDotLoader />
                        </div>
                    ) : quitRemarks.length > 0 ? (
                        <Table
                            columns={remarkColumns}
                            data={quitRemarks}
                            totalCount={quitRemarks.length}
                            limit={10} 
                            currentPage={1} 
                            setCurrentPage={() => {}} 
                            loading={false}
                            sortChange={() => {}} 
                            filterChange={() => {}} 
                            isGlobalFilter={false}
                            type = 'client'
                        />
                    ) : (
                        <div className={styles.render_remark_fallback}>
                            <img
                                src="https://play-lh.googleusercontent.com/ue1pf33HUuVLlZ_Xiysai5Akr6yugLtU502nZPFtibDFUVfkLG-K_aH4qNOHrdGx2vh8"
                                alt="No Remark Available"
                            />
                            <p className={styles.fallback_text}>No remark added</p>
                        </div>
                    )
                }
            />
        );
    };
    

    const renderActiveJobs = () => {
        const {firstname, lastname} = selectedRowData
        return(
            <Popup
            show={showActiveJobs}
            closePopup={()=>{
                setSelectedRowData('')
                setShowActiveJobs(false)
            }}
            size='lg'
            heading={`Current jobs of ${firstname} ${lastname}`}
            popupContent = {<CurrentJobs rowData={selectedRowData}/>}
            />
        )
    }


    const handlePopups = () => {
        if(showCallerHistory) return renderJobHistory()
        if(showActiveJobs) return renderActiveJobs()
        if (showRemarkPopup) return renderRemarkPopup();
        else
        return null
    }

    return(
        <>
        {getMatchedCallerApi.error && <p>{typeof getMatchedCallerApi.error == 'object' && 
        getMatchedCallerApi.error !== null &&
        !Array.isArray(getMatchedCallerApi.error)
        ? getMatchedCallerApi.error.error || getMatchedCallerApi.error.msg : getMatchedCallerApi.error}</p>}
        <Table
        columns={columns}
        data={data}
        totalCount={total}
        limit={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        sortChange={sortChange}
        filterChange = {filterChange}
        isGlobalFilter = {true}
        />
        {handlePopups()}
        </>
    )
}

export default MatchTable