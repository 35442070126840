import React, { useState, useEffect } from 'react'
import styles from '../../assets/stylesheets/pages/tools/tools.module.scss'
import jwt_decode from "jwt-decode";
//custom imports
import Heading from '../../uicomponents/heading'
import Card from '../../uicomponents/card'
import constants from '../../util/constants';

const Tools = (props) => {
    const [toolOptions, setToolOptions] = useState([])

    const role = jwt_decode(localStorage.authToken).auth

    useEffect(()=>{
        if(role === constants.customRole.ADMIN){
            setToolOptions([
                'Quiz',
                'Account creation',
                'Dashboard gateway',
                'Exotel account setup',
                'Exotel account setup (new)',
                'Wild card tool',
                'Call Dialer',
                'Exophone project link',
                'DTDC Leads Unmasking',
                'WhatsApp On-Demand Campaign',
                'Agent Metadata Tool',
                'Merge Report'
            ])
        }else if(role == constants.customRole.PROJECT_MANAGER){
            setToolOptions([
                'Call Dialer',
                'Exophone project link',
                'DTDC Leads Unmasking',
                'Exotel account setup (new)',
                'WhatsApp On-Demand Campaign',
                'Agent Metadata Tool',
                'Merge Report'
            ])
        }else if(role == constants.customRole.CONTENT_MANAGER){
            setToolOptions([
                'Quiz',
                'Call Dialer',
                'DTDC Leads Unmasking',
                'WhatsApp On-Demand Campaign'
            ])

        }else if([constants.customRole.TMP, constants.customRole.COACH, constants.customRole.TEAM_LEAD].includes(role)){
            setToolOptions([
                'Call Dialer',
                'WhatsApp On-Demand Campaign',
                'Merge Report'
            ])
        }else{
            setToolOptions([
                'Call Dialer'
            ])
        }
    },[])

    
    const goToPage = (option) => {
        switch (option) {
            case 'Account creation':
                return props.history.push({
                    pathname: `${props.match.path}/account-creation`
                })
            case 'Wild card tool':
                return props.history.push({
                    pathname: `${props.match.path}/wildcard`
                })
            case 'Quiz':
                return props.history.push({
                    pathname: `${props.match.path}/quiz`
                })
            case 'Dashboard gateway':
                return props.history.push({
                    pathname: `${props.match.path}/dashboard-gateway`
                })
            case 'Exotel account setup':
                return props.history.push({
                    pathname: `${props.match.path}/exotel-account`
                })
            case 'Exotel account setup (new)':
                return props.history.push({
                    pathname: `${props.match.path}/exotel-account-new`
                })
            case 'Call Dialer':
                return props.history.push({
                    pathname: `${props.match.path}/call-dialer`
                })
            case 'Exophone project link':
                return props.history.push({
                    pathname: `${props.match.path}/exophone-project-link`
                })
            case 'DTDC Leads Unmasking':
                return props.history.push({
                    pathname: `${props.match.path}/dtdc-leads-unmasking`
                })
            case 'WhatsApp On-Demand Campaign':
                return props.history.push({
                    pathname: `${props.match.path}/whatsapp-on-demand-campaign`
                })
            case 'Agent Metadata Tool':
                return props.history.push({
                    pathname: `${props.match.path}/agent-metadata-tool`
                })
            case 'Merge Report':
                return props.history.push({
                    pathname: `${props.match.path}/merge-report`
                })
            default:
                break;
        }
        return false
    }

    return(
        <div className={styles['page-wrapper']}>
        <Heading text={'Tools'} />
        <div className={styles['user-grid-wrapper']}>
            {
                toolOptions.map(option => {
                    return <div className={styles['grid-item']}>
                    <Card info={{title: option}} onClick={()=> goToPage(option)}/>
                    </div>
                })
            }
        </div>
        </div>
    )
}

export default Tools